.brand-switch {
  .dropdown-header,
  .menu-item a {
    display: flex;
    align-items: center;
  }

  .dropdown-menu {
    margin-bottom: 15px;
  }

  .dropdown-header {
    height: 40px;
  }

  .menu-item {
    a {
      height: 36px;
      margin-bottom: 4px;
      text-decoration: none;
      width: 100%;

      &:hover {
        .brand-title {
          font-weight: bold;
        }
      }
    }
  }

  .menu-item,
  .title-container {
    img {
      margin-right: 10px;
      height: 20px;
      width: auto;
    }
  }

  .title-container {
    font-weight: normal;
    align-items: center;
    display: inline-flex;

    &.no-title {
      margin-left: 5px;
    }

    .brand-title {
      font-weight: bold;
    }

    img {
      margin-left: 5px;
    }
  }

  > .btn-group {
    > ul {
      min-width: 300px;
    }

    &.open {
      > button {
        background-color: $color-grey1;

        i {
          transform: rotate(180deg);
        }
      }
    }

    > button {
      text-transform: none;
      display: inline-flex;
      align-items: center;
      line-height: normal;
      height: 34px;
      border-radius: 15px;
      background-color: white;
      padding: 0 1em 0 0.8em;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

      i {
        font-weight: bold;
        margin-left: 8px;
        transition: all 0.5s;
        font-size: 18px;
        transform: rotate(0deg);
      }
    }
  }
}

@media (max-width: 767px) {
  .brand-switch {
    .dropdown-menu {
      left: -110px;
    }
  }
}
