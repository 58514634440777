@charset "UTF-8";

/*!
 * Bootstrap  v5.3.0 (https://getbootstrap.com/)
 * Copyright 2011-2023 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

@import 'modules/variables';
//@import 'modules/base';
//@import 'modules/container';
//@import 'modules/table';
//@import 'modules/form';
//@import 'modules/input';
//@import 'modules/validation';
//@import 'modules/btn';
@import 'modules/dropdown';
//@import 'modules/nav';
//@import 'modules/card';
@import 'modules/accordion';
//@import 'modules/page';
//@import 'modules/alert';
//@import 'modules/list-group';
@import 'modules/modal';
//@import 'modules/tooltip';
//@import 'modules/carousel';
//@import 'modules/spinner';
//@import 'modules/offcanvas';
//@import 'modules/text';
//@import 'modules/position';
//@import 'modules/utils';
