.card-wrapper {
  width: 100%;
  vertical-align: top;
  display: inline-block;
  padding: 0 5px;
  margin-bottom: 25px;

  .card-car {
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 13px -2px;
    border-radius: 4px;
    transition: all ease .3s;
    min-height: 180px;
    flex-wrap: wrap;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 5px -2px;
      cursor: pointer;
    }

    .car-image {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-height: 160px;
        object-fit: contain;
        border: 10px solid white;
        border-radius: 20px;
      }
    }

    .card-content {
      width: 50%;
      font-family: inherit;
      padding: 0 10px;
      line-height: 30px;
      display: flex;
      flex-direction: column;
      padding-bottom: 10px;

      .title {
        font-weight: bold;
        font-size: 18px;
        color: $dark
      }

      .car-plate-number {
        color: #B4B4B4;
        font-size: 17px;
      }

      .car-infos {
        color: $dark;
        font-size: 14px;
        line-height: 18px;
      }

      .car-price {
        .booking-cost {
          font-weight: bold;
          font-size: 20px;
          display: block;

        }
      }

      .visible-md {
        margin-top: auto;
      }

      .btn-book {
        margin: 0 auto;
        width: 100%;

        &:after {
          content: ' ',
        }
      }

      &.fullspace.visible-sm.visible-xs {
        flex: 1 100%;
        flex-direction: row;
      }
    }
  }
}

.booking-title {
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 10px 35px;
  color: $secondary;
}

.booked-car {
  background: $background;
  padding: 30px 0;
  color: $secondary;
  margin: 35px 0 30px;

  .booked-car-first {
    padding: 0 50px;

    $voucher-star-color: grey;

    .voucher-price-star {
      vertical-align: top;
      color: $voucher-star-color;
      margin-left: 5px;
    }

    .voucher-info-star {
      color: $voucher-star-color;
    }
  }

  .booked-car-second {
    padding: 0 50px;
  }

  .booked-car-info {
    .car-header {
      text-transform: uppercase;
      font-size: 15px;

      .price-value {
        margin-left: 5px;
      }

      .type {
        margin-right: 10px;
      }

      .price-value,
      .type {
        font-size: 40px;
        font-weight: bold;
      }

      .years {
        span:first-child {
          margin: 0 3px;
        }
      }
    }

    .car-content {
      font-family: inherit;
      font-size: 15px;
      margin-bottom: 25px;

      .address {
        margin-top: 20px;
      }

      .rrs-price {
        margin-top: 20px;

        &:empty {
          display: none;
        }
      }

      .speed {
        font-family: inherit;
        margin: 30px 0 0;
        text-transform: uppercase;
      }

      .time {
        font-size: 18px;
        font-weight: bold;
        margin-top: 40px;

        $dd-margin: 5px;
        $dd-spacing: 8px;

        .time-separator {
          letter-spacing: $dd-spacing;
          margin-left: $dd-spacing + $dd-margin;
          margin-right: $dd-margin;
        }
      }
    }
  }

  &.my-bookings-card {
    border: 1px solid #999999;

    .booked-car-info {
      .car-header {
        .row {
          align-items: center;
          display: flex;
        }

        .rrs-info {
          align-items: flex-end;
          display: inline-flex;
          flex-direction: column;
        }

        .included-kms {
          text-transform: initial;
        }

        .price {
          display: inline-block;

          .price-value {
            margin-top: 10px;
            font-size: 15px;
          }
        }
      }
    }

    .car-content {
      font-family: inherit;
    }

    .speed {
      display: none;
    }

    .time {
      .from,
      .time-separator {
        color: $secondary;
        font-weight: bold;
      }

      .to {
        color: #666666;
        font-weight: bold;
      }
    }

    &.inactive {
      color: #666666;

      .time {
        display: none;
      }

      .car-image {
        img {
          opacity: 0.5;
        }
      }
    }

    &.active {
      border: 1px solid #ffcc33;

      .time {
        .from,
        .time-separator {
          color: #666666;
          font-weight: 700;
        }

        .to {
          color: $secondary;
          font-weight: 700;
        }
      }

      .booked-car-info {
        .car-header {
          .price {
            display: inline-block;
            margin-top: 15px;

            .price-value {
              font-size: 25px;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}

.show-car-characteristics {
  background: $primary;
  padding: 5px 45px 5px 20px;
  display: inline-block;
  margin-top: 21px;
  position: relative;
  cursor: pointer;

  &:after {
    content: "\e90b";
    // use 'important' to prevent issues with browser extensions that change fonts
    font-family: 'icomoon' !important;
    font-size: 28px;
    font-weight: bold;
    position: absolute;
    right: 15px;
    top: 0;
    line-height: 1;
    width: 26px;
    height: 26px;
  }

  span {
    display: inline-block;
    width: 100%;
  }
}

.booked-car-characteristics {
  font-size: 13px;
  background: #f4f4f4;
  padding: 25px 35px 35px;
  color: #333333;
  margin: 20px 0 30px;
}

.car-features-list {
  font-size: 13px;
  background: #ffffff;
  border: 1px solid $primary;
  padding: 20px 40px 35px;
  display: none;

  &.show {
    display: block;
  }

  .characteristics-title {
    font-weight: bold;
  }
}

.parking-schedule {
  font-size: 13px;
  background: #ffffff;
  border: 1px solid $primary;
  padding: 20px 40px 30px;
  display: none;

  &.show {
    display: block;
  }

  .characteristics-title {
    font-weight: bold;
  }
}

.show-parking-schedule {
  background: $primary;
  padding: 5px 45px 5px 20px;
  display: inline-block;
  margin-top: 21px;
  position: relative;

  &.not-open {
    cursor: pointer;

    &:after {
      content: "\e90b";
      // use 'important' to prevent issues with browser extensions that change fonts
      font-family: 'icomoon' !important;
      font-size: 28px;
      font-weight: bold;
      position: absolute;
      right: 15px;
      top: 0;
      line-height: 1;
      width: 26px;
      height: 26px;
    }
  }

  span {
    display: inline-block;
    width: 100%;
  }
}

.characteristics-title {
  text-transform: uppercase;
  margin: 25px 0 15px;
}

.characteristics {
  .car-parameters {
    i {
      font-size: 20px;

      & + span {
        margin-left: 5px;
      }
    }

    .lower-indicator {
      margin-right: 15px;
      margin-left: 4px;
      font-size: 14px;
    }
  }
}

.success-booking-message {
  margin-bottom: 45px;
  font-size: 15px;
  text-align: center;
  color: $secondary;

  .title {
    font-size: 30px;
    font-weight: bold;
  }

  .subtitle {
    font-size: 20px;
    margin-bottom: 30px;
    font-weight: bold;
  }
}

.my-reservations {
  .booking-title {
    font-family: inherit;
    text-transform: uppercase;
    margin-top: 65px;

    &.active {
      color: #ffcc33;

      & + hr {
        background: #ffcc33;
      }
    }

    &.inactive {
      margin-top: 50px;
      color: #c3c3c3;

      & + hr {
        background: #c3c3c3;
      }
    }
  }
}

@media (max-width: 991px) {
  .car-features-list {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .card-wrapper {
    width: 100%;
    padding: 0;

    .card-car {
      .card-content {
        .card-header {
          .car-info {
            div:first-child {
              .title {
                font-size: 16px;
              }
            }

            .features {
              i {
                font-size: 16px;
              }
            }
          }
        }

        .car-price {
          .title {
            font-size: 26px;
          }
        }
      }
    }
  }

  .booked-car .booked-car-info .car-content {
    .speed {
      margin: 20px 0;
    }

    .time {
      margin-bottom: 20px;
    }
  }

  .booked-car-characteristics {
    .characteristics-title {
      font-weight: bold;
    }
  }

  .show-car-characteristics {
    &:after {
      right: 10px;
    }
  }

  .show-parking-schedule {
    &.not-open:after {
      right: 10px;
    }
  }
}
