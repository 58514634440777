$heightBookPage: calc(var(--height) - #{$newHeaderHeight});

.search-filters-layout {
  .flexit {
    display: flex;

    .list {
      width: 460px;
      padding: 0;
    }

    .gmap {
      width: calc(100% - 460px);
      padding: 0;
    }
  }

  .filters-panel {
    background: #fff;
    display: flex;
  }

  .filtering-panel {
    display: flex;
    border-bottom: 1px solid $primary;
    padding: 5px 0 5px 10px;

    .filter-item {
      @include user-select-none();
      display: inline-block;

      .dropdown-menu {
        margin-top: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        font-size: 1em;
      }

      .title {
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        line-height: 40px;
      }

      .trip-type {
        width: 300px;

        .actions-wrapper {
          display: flex;
          justify-content: space-between;
          padding: 10px 32px;

          .btn-save {
            padding: 4px 6px;
            border: 1px solid transparent;
            cursor: pointer;
          }

          a {
            padding: 4px 6px;
            cursor: pointer;
          }
        }
      }

      .places {
        width: 400px;

        button {
          &:focus,
          &:active,
          &:hover {
            outline: 0;
          }
        }
      }

      .energy {
        width: 400px;
        text-align: center;
        padding: 0 0 20px 0;
        margin: 0 5px;

        .custom-checkbox {
          display: inline-block;
          margin-top: 20px;

          label {
            margin-right: 20px;
          }
        }
      }

      .others {
        width: 450px;
        text-align: center;
        padding: 0 0 20px 0;

        .custom-checkbox {
          display: inline-flex;
          margin-top: 20px;
          width: 31%;
          text-align: left;
          margin-right: 5px;
          flex-flow: wrap row;

          label {
            display: inline-flex;
          }
        }
      }

      .sep {
        border-bottom: 1px solid $primary;
      }

      .vehicle-type {
        display: flex;
        padding: 10px;

        &.use-white-icon {
          .radio-icon input {
            &:checked ~ label {
              .label-icon {
                &.tourism {
                  background-image: url('/assets/images/vehicleTourismWhite.svg');
                }
                &.commercial {
                  background-image: url('/assets/images/vehicleCommercialWhite.svg');
                }
              }
            }
          }
        }

        .radio-icon {
          margin: 0 5px;

          input {
            display: none;

            &:checked ~ label {
              border: 1px solid $accent;
              color: $dark;
            }
          }

          label {
            display: block;
            border: 1px solid $primary;
            flex-direction: column;
            text-align: center;
            background-color: #f7f7f7;
            border-radius: 4px;
            padding: 10px 30px;
            transition: all ease 0.3s;

            .label-icon {
              display: block;
              height: 40px;
              background-repeat: no-repeat;
              width: 80px;
              background-position: center;

              &.tourism {
                background-image: url('/assets/images/vehicleTourism.svg');
              }

              &.commercial {
                background-image: url('/assets/images/vehicleCommercial.svg');
              }
            }

            .info {
              display: block;
            }
          }
        }
      }

      .custom-radio {
        width: 300px;
        padding: 10px 0;
        text-align: center;
        margin: 10px 0 0;

        span {
          margin-right: 5px;
          margin-left: 10px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .filters-btn {
        &.border-primary-active > button {
          background-color: #f7f7f7;
        }

        > button {
          border-radius: 20px;
          background: white;
          border: 1px solid $primary;
          font-family: inherit;
          font-size: inherit;
          font-weight: normal;
          outline: unset;
          cursor: pointer;
          text-transform: unset;
          height: 40px;
          line-height: 40px;
          padding: 0 15px;
          margin-right: 10px;
        }
      }
    }

    &.mobile {
      .filter-item {
        .vehicle-type {
          padding: 10px 0;
          .radio-icon {
            margin: 0 10px 0 0;
          }
        }

        .custom-radio {
          width: 100%;
          text-align: left;

          span {
            margin: 0;
          }

          label {
            margin-right: 10px;
            span {
              margin-left: 0;
            }
          }
        }
      }
    }
  }

  .base-filters-panel {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .geosuggest__input {
      height: 36px;
    }

    .no-result {
      text-align: center;
      padding: 30px 10px;
    }
  }

  .more-filters-panel {
    border-left: none;
    margin-left: -10px;

    label {
      font-size: 12px;
    }

    .category-select-wrapper {
      margin-top: 5px;
    }

    .custom-checkbox {
      display: inline-block;
      margin: 6px 35px 6px 0;
    }
  }

  .map-panel {
    @include flex-col;

    &.large-panel {
      position: relative;

      .close-icon {
        display: block;
        position: absolute;
        top: 10px;
        right: 14px;
        background: white;
        box-shadow: 0 0 10px 0 rgba(51, 51, 51, 0.25);
        cursor: pointer;
        font-size: 25px;
        color: $secondary;
        z-index: 10;

        &:hover {
          background: #ececec;
        }
      }

      .map-panel-header {
        display: none;
      }

      .map {
        height: 100%;
        width: calc(100% - 200px);
        margin-left: 200px;
      }

      .custom-scroll {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 200px;
      }

      .agency-list {
        height: 453px;

        &-header {
          display: none;
        }

        &-item {
          border-bottom: none;
          padding: 10px 20px 10px 13px;
        }

        .contact {
          float: none;
        }

        .phone {
          float: none;
          display: block;
          margin-top: 20px;
        }
      }
    }

    .close-icon {
      display: none;
    }

    &-header {
      border-bottom: 1px solid $primary;
      font-size: 14px;
      line-height: 16px;
      padding: 10px 0;
      text-align: center;
      display: none;
    }

    .map {
      height: calc(100vh - (111px + #{$heightBookPage}));
      background: $background-light;
    }

    .custom-scroll {
      @include av-height;
      .outer-container {
        @include av-height;
        .inner-container {
          @include av-height;
          .content-wrapper {
            @include av-height;
          }
        }
      }

      .custom-scrollbar {
        width: 6px;
        right: 0;
        padding: 0;
        background: $light;
        opacity: 1;

        .custom-scroll-handle {
          .inner-handle {
            margin-top: 0;
            background: $accent;
            border-radius: 0;
            height: 100%;
          }
        }
      }
    }

    $agency-header-mh: 10px;

    .agency-list {
      @include av-height;
      width: 100%;
      text-align: left;
      display: none;

      .no-results {
        align-items: center;
        justify-content: center;
        display: flex;
        height: 100%;
      }

      &-header {
        padding: $agency-header-mh 15px $agency-header-mh 10px;
        border-top: 1px solid #cccccc;
        border-bottom: 1px solid #cccccc;
        background: $background;
        color: $secondary;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        width: 100%;

        $header-content-fs: 13px;

        .title {
          font-size: $header-content-fs;
          font-weight: bold;
          width: 100%;
          font-style: italic;
        }

        .enlarge {
          color: #333;
          background: white;
          padding: 4px 8px;
          font-size: $header-content-fs;
          box-shadow: 0 0 10px 0 rgba(51, 51, 51, 0.15);

          &:hover {
            background: #ececec;
          }
        }
      }

      .contact {
        float: left;
      }

      .phone {
        float: right;
        font-size: 14px;
      }

      $agency-li-border: 1px solid $light;

      &-item {
        font-size: 13px;
        border-bottom: $agency-li-border;
        color: $secondary;
        padding: 8px 20px 8px 10px;
        cursor: pointer;
        width: 100%;
        transition: background, color 0.1s;

        &:last-child {
          border-bottom: none;
        }

        &:first-child {
          border-bottom: $agency-li-border;
        }

        &:hover {
          background: #ffdb60;
          color: #333;
        }

        &.active {
          background: $secondary;
          color: #fff;
        }
      }
    }
  }

  .filter-chip {
    background-color: $accent2;
    padding: 6px 25px 6px 8px;
    color: #000;
    display: inline-block;
    margin-right: 7px;
    margin-top: 10px;
    font-size: 0;
    min-width: 95px;
    position: relative;
    text-align: center;

    .filter-label {
      font-size: 12px;
    }

    .remove-btn {
      font-size: 18px;
      position: absolute;
      right: 7px;
      top: 5px;
      cursor: pointer;
    }
  }
}

.vehicles-loader {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;

  img {
    height: 200px;
  }
}

.result-wrapper {
  background: #fff;

  .parking-set {
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    cursor: pointer;
    font-weight: bold;

    .agency-name {
      text-align: center;
      width: 100%;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      .react-svg {
        display: inline-block;
        padding: 10px;
        position: absolute;
        left: 0;
        margin-left: 5px;

        svg {
          width: 18px;
          height: 20px;
          fill: #000000;
        }
      }
    }
  }

  .result-filter {
    display: flex;
    flex-direction: column;
    padding: 20px 16px;
    position: relative;

    .info-text {
      font-size: 18px;
      font-weight: bold;
    }

    $arrow-margin-right: 5px;
    $sort-font-size: 14px;
    $sort-width-margin: 7px;
    $sort-height-margin: 4px;

    .container-select {
      line-height: 25px;
      height: 25px;
    }
    .sort {
      float: right;
    }
  }

  .result-content {
    padding: 10px 5px 45px;
    height: calc(100vh - (201px + #{$heightBookPage}));
    overflow-y: scroll;
  }
}

@media (max-width: 991px) {
  .search-filters-layout {
    .more-filters-panel {
      padding: 25px;

      .custom-checkbox {
        margin: 6px 15px 6px 0;
      }
    }

    .filtering-panel {
      &.mobile {
        flex-direction: column;
        border-bottom: 0;
        margin: 0 10px;
        padding: 5px 20px;

        .filter-item {
          display: block;
          @include user-select-none();
          border-bottom: 1px solid #efefef;

          .title {
            text-align: left;
          }
          .energy,
          .places,
          .others {
            width: unset;
            text-align: left;
          }
          .energy {
            margin: 0;
          }
        }

        .submit-container {
          margin: 0 auto;

          .btn {
            width: 300px;
            margin-top: 20px;
            &.disabled {
              background: $background-dark !important;
              pointer-events: none;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .date-time-picker-field .time-wrapper .picker {
    right: -1px;
  }

  .search-filters-layout {
    padding: 15px 0 40px;

    .search-form {
      height: auto;
    }

    .filtering-panel {
      &.mobile {
        flex-direction: column;
        border-bottom: 0;
        margin: 0;
        padding: 5px 20px;

        .filter-item {
          display: block;
          @include user-select-none();
          border-bottom: 1px solid #efefef;

          .title {
            text-align: left;
          }
          .energy,
          .places,
          .others {
            width: unset;
            text-align: left;
          }
          .energy {
            margin: 0;
          }
        }

        .submit-container {
          margin: 0 auto;

          .btn {
            width: 300px;
            margin-top: 20px;

            &.disabled {
              background: $background-dark !important;
              pointer-events: none;
            }
          }
        }
      }
    }

    .search-detail {
      background: rgba(118, 118, 128, 0.1);
      margin: 0 20px;
      border-radius: 4px;
      padding: 10px 15px;
      cursor: pointer;

      .address {
        font-family: inherit;
      }

      span {
        @include font-weight-light;
      }

      div.svg-arrow {
        transform: rotate(180deg);
        fill: #ffcc33;
        width: 10px;
        line-height: inherit;
        display: inline-block;
        margin: auto 10px;
      }
      //https://codepen.io/fromwireframes/pen/Nwjjmz
      .content-placeholder {
        animation: linear 1.5s placeHolderShimmer infinite;
        background: #f6f7f8;
        background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
        background-size: 500px;
        width: 320px;
        height: 38px;
        clip-path: url('#resumePath');
        // 	Safari render fix
        transform: translateZ(0);
        display: inline-block;
      }

      @keyframes placeHolderShimmer {
        0% {
          background-position: -460px 0;
        }
        100% {
          background-position: 460px 0;
        }
      }
    }

    .flexit {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap-reverse;

      .gmap {
        width: 100%;

        .map {
          height: 30vh;
        }
      }
      .list {
        width: 100%;
      }
    }

    .filters-panel {
      background: transparent;
      border: none;
      height: auto;
    }

    .base-filters-panel {
      .form-content {
        width: 100%;
        padding: 0 10px 10px;
      }
    }

    .map-panel {
      display: initial;
    }
  }

  #FiltersMobile-popup {
    .title-filters {
      font-size: 20px;
      text-align: center;
      margin-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid $color-grey4;
    }
    a.clear-filters-action {
      position: absolute;
      left: 20px;
      top: 25px;
    }
  }

  .result-wrapper {
    padding-top: 20px;

    .result-filter {
      text-align: left;

      .info-text {
        float: none;
        font-size: 15px;
      }
    }

    .result-content {
      padding: 15px 0 30px;
      height: calc(100vh - (201px + #{$heightBookPage}));
    }
  }
}

.FiltersMobile-popup.modal {
  .modal-content {
    height: 100vh;
  }
}
