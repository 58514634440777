.booking-cancel-popup {
  color: #333;

  .scope {
    &-icon {
      max-width: 110px;
      max-height: 110px;
    }

    &-button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      font-size: 14px;
      min-height: 50px;
      min-width: 200px;
      cursor: pointer;

      &-center {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-container {
        display: flex;

        &.sub-class {
          &-left {
            justify-content: flex-end;
          }

          &-right {
            justify-content: flex-start;
          }
        }
      }

      &-cancel {
        font-weight: bold;

        &.sub-class-disabled {
          @include disabledButtonColors;
          cursor: wait;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .booking-cancel-popup {
    .scope {
      &-button {
        width: 100%;
      }
    }
  }
}
