.container-language {
  .dropdown-toggle::after {
    content: revert;
  }

  .dropdown-header {
    display: block;
    padding: 3px 20px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #777777;
    white-space: nowrap;
  }

  .dropdown-menu {
    font-size: 14px;
    background-color: #ffffff;
    border: 1px solid #cccccc;
  }

  .dropdown-item {
    cursor: pointer;
  }
}
