.react-svg {
  display: inline-block;

  > div {
    display: flex;
  }
}

.generic-submit-button {
  cursor: pointer;
  min-height: 45px;
  padding: 0 14px;
  border: 0;
  border-radius: 4px;
  outline: none;
  font-size: 16px;
  font-weight: bold;
  font-family: inherit;
  background: #efefef;
}
