.spinner {
  border: 0.15em solid #e1e1e1;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  animation: locationSpinAnimation 2s linear infinite;
}

@keyframes locationSpinAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
