.redirect-mobile.not-modal {
  .register-mobile {
    margin: 5vh auto;
    max-width: 600px;
  }
}

.redirect-mobile {
  div {
    display: flex;
    flex-direction: column;

    &> img {
      align-self: center;
    }
  }
  .appstore-links {
    display: none;
  }

  span {
    padding: 0 50px;
    font-weight: bold;
  }

  .btn::after {
    content: '';
  }

  button.center {
    margin: 10px auto;
  }

  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0.33s, opacity 0.33s linear;

}

@media (max-width: 767px) {
  .redirect-mobile {
    .appstore-links {
      display: block;
      margin: 10px auto 30px;

      img {
        width: 150px;
      }
    }
  }
}
