@font-face {
  font-family: 'icomoon';
  src: url('/assets/fonts/icomoon.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  // use 'important' to prevent issues with browser extensions that change fonts
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-clock-circle:before {
  content: '\e91c';
}
.icon-calendar-circle:before {
  content: '\e91d';
}
.icon-devices-circle:before {
  content: '\e91e';
}
.icon-tasks-circle:before {
  content: '\e91f';
}
.icon-click-circle:before {
  content: '\e920';
}
.icon-location-circle:before {
  content: '\e921';
}
.icon-bag:before {
  content: '\e909';
}
.icon-calendar2:before {
  content: '\e90a';
}
.icon-chevron-down:before {
  content: '\e90b';
}
.icon-chevron-left:before {
  content: '\e90c';
}
.icon-chevron-right:before {
  content: '\e90d';
}
.icon-chevron-up:before {
  content: '\e90e';
}
.icon-close:before {
  content: '\e90f';
}
.icon-compact:before {
  content: '\e910';
}
.icon-door:before {
  content: '\e911';
}
.icon-download:before {
  content: '\e912';
}
.icon-electric:before {
  content: '\e913';
}
.icon-gas:before {
  content: '\e914';
}
.icon-marker:before {
  content: '\e915';
}
.icon-monospace:before {
  content: '\e916';
}
.icon-seat:before {
  content: '\e917';
}
.icon-sedan:before {
  content: '\e918';
}
.icon-time:before {
  content: '\e919';
}
.icon-vu:before {
  content: '\e91a';
}
.icon-angle-right:before {
  content: '\f105';
}
.icon-angle-down:before {
  content: '\f107';
}
.icon-cross:before {
  content: '\e91b';
}
.icon-smartphone:before {
  content: '\e900';
}
.icon-bell:before {
  content: '\e901';
}
.icon-calendar:before {
  content: '\e902';
}
.icon-car:before {
  content: '\e903';
}
.icon-checkmark:before {
  content: '\e904';
}
.icon-clock:before {
  content: '\e905';
}
.icon-flash:before {
  content: '\e906';
}
.icon-pin:before {
  content: '\e907';
}
.icon-upload:before {
  content: '\e908';
}
