.keycloak-page {
  font-size: 1rem;
  min-height: 75vh;
  padding: 2em;

  button {
    font-size: inherit;
    text-transform: capitalize;
  }

  .scope {
    &-main > div:not(:first-child) {
      margin-top: 10px;
    }

    &-google-parent {
      display: inline-flex;
      cursor: pointer;

      svg {
        height: 44px;
        width: 44px;
      }
    }

    &-line-items {
      display: flex;
      align-items: center;

      > *:not(:first-child) {
        margin-left: 20px;
      }
    }
  }
}
