.bookings-map {
  height: 100%;

  .map-info-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;

    .google-map-popup {
      display: flex;
      flex-direction: row;
      align-items: center;
      background: #fffefa;
      box-shadow: 0 0 10px 0 rgba(51, 51, 51, 0.45);
      border-radius: 4px;

      .agency-name {
        width: 200px;
        min-height: 50px;
        border-radius: 4px;
        padding: 5px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 20px;
        text-align: center;
        font-weight: bold;
      }

      .google-map-popup-text {
        white-space: nowrap;
        font-size: 14px;
        padding-left: 7px;
        padding-right: 7px;
        font-family: inherit;
      }
    }

    .arrow-down {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 20px 20px 0;
      filter: drop-shadow(0 5px 2px rgba(0, 0, 0, .22));
    }
  }


  .gm-style-pbc {
    opacity: 0 !important; // scss-lint:disable ImportantRule
  } // remove helping overlay when doing two-finger scroll on the map | not officially supported by google maps

  .map-marker {
    width: 40px;
  }

  .agency-circle {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    filter: drop-shadow(0px 1px 1px black);
    font-size: 14px;
    cursor: pointer;
    position: relative;

    span.figure {
      position: absolute;
    }
  }
}
