.mobile-banner {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  .download-app {
    text-align: center;
  }

  .title {
    // @include font-weight-light;
    font-weight: 500;
  }

  .phone-image {
    width: 100%;

    &-container {
      display: flex;
    }
  }

  .mobile-store-img {
    height: 40px;
    margin: 10px;
  }
}

@media (min-width: 768px) {
  .mobile-banner {
    > div {
      max-width: 50%;
    }

    > div:only-child {
      max-width: 100%;
    }
  }
}

@media (max-width: 720px) {
  .mobile-banner {
    flex-direction: column-reverse;
  }
}
