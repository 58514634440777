$usageTypeHeight: 40px;
$searchHeight: 130px;

.search-booking-homepage {
  display: flex;
  flex-direction: row;
  align-items: center;

  &.both-usage-types {
    min-height: $searchHeight + $usageTypeHeight;
  }

  .field-wrapper {
    label {
      text-transform: lowercase;
    }
  }
  .search-wrapper {
    min-height: $searchHeight;
    background-color: $color-white;
    align-items: center;
  }

  .dates {
    &.both-usage-types {
      padding-bottom: $usageTypeHeight;
    }
  }

  .usage-types {
    height: $usageTypeHeight;
    line-height: $usageTypeHeight;

    .custom-radio {
      span:nth-child(2) {
        margin-left: 20px;
      }
    }
  }

  .error-border {
    border-bottom: 1px dashed $danger;
  }

  .error-request {
    width: 100%;
    text-align: right;
  }

  &-btn {
    min-width: 210px;
    margin: 18px 20px 0 0;
    height: 56px;
    border-radius: 4px;

    &.both-usage-types {
      margin-bottom: $usageTypeHeight;
    }

    &:after {
      content: '';
    }
  }

  .checkout-tabs-wrap {
    width: 100%;
  }
}

.search-form {
  background-color: $color-white;
  border-radius: 4px;

  &.search-header {
    height: 40px;
    padding-left: 1vw;
    display: flex;
    align-items: center;

    .dates-wrapper {
      margin-left: 1.5vw;
    }

    .location input {
      width: 26vw;
      min-width: 280px;
      transition: 0.3s ease all;
    }

    .location,
    .dates {
      padding: 0;

      .both-usage-types {
        padding-bottom: $usageTypeHeight;
      }

      label {
        display: none;
      }

      &.field-wrapper {
        .input-wrapper input {
          height: 40px;
          border: none;
          line-height: 40px;
        }
      }
    }

    .search-booking-homepage-btn {
      display: none;
    }
  }

  .geosuggest {
    margin-bottom: $geo-suggest-margin;
  }

  .error-msg {
    position: absolute;
    left: calc(100vw - 50% - 100px);
    width: 200px;
    background-color: white;
    border-radius: 11px;
    padding: 5px 10px;
    text-align: center;
    margin-top: 5px;
    z-index: 10;
    box-shadow: 0 0 5px #00000033;

    &:empty {
      display: none;
    }
  }

  .location {
    padding: 20px;

    input {
      border-radius: 4px !important;
      background-color: rgba(118, 118, 128, 0.12);
      border-color: transparent !important;
    }
  }

  .dates-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .picker__list {
    padding: initial;
  }

  .picker--time .picker__button--clear {
    display: none;
  }

  .picker__button--clear {
    display: none;
  }

  .picker__footer {
    display: flex;
    justify-content: space-around;

    > button {
      width: auto;
    }
  }
}

@media (max-width: 767px) {
  .search-booking-homepage {
    display: flex;
    flex-direction: column;
    align-items: center;

    .search-wrapper {
      height: auto;
      background-color: $color-white;
      align-items: center;
    }

    .dates {
      &.both-usage-types {
        padding-bottom: 0;
      }
    }
  }

  .search-form {
    background-color: unset !important;
    height: auto;
    padding-bottom: 10px;

    .location {
      background-color: $color-white;
      border-radius: 4px;
      padding: 10px;
      margin-bottom: 10px;
    }

    .dates-wrapper {
      flex-direction: column;
      margin-bottom: 10px;
    }
  }
}

@media (max-width: 1492px) {
  .search-form {
    &.search-header {
      .location input {
        width: 30vw;
        min-width: 400px;
      }
    }
  }
}

@media (max-width: 1050px) {
  .search-form {
    &.search-header {
      .location input {
        width: 25vw;
        min-width: 200px;
      }
    }
  }
}
