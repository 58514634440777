$flagCountrySize: 100px;
$flagCountrySmallMobile: 70px;

.flag-country-selector {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 20px;
  flex-wrap: wrap;

  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33%;
    height: 150px;
    transition: all ease 1s;

    .country-title {
      margin-top: 10px;
    }

    i {
      display: flex;
      height: $flagCountrySize;
      width: $flagCountrySize;
      margin: 5px;
      align-items: flex-end;
      justify-content: center;
      flex-wrap: wrap;
      background-position: center top;
      background-repeat: no-repeat;
      background-size: contain;
      cursor: pointer;
      transition: all ease 0.3s;

      &:hover {
        height: $flagCountrySize;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.24);
        border-radius: 100px;
      }
    }

    &:hover {
      cursor: pointer;
      font-weight: bold;
    }
  }
}

@media (max-width: 400px) {
  .flag-country-selector {
    li {
      i {
        height: $flagCountrySmallMobile;
        width: $flagCountrySmallMobile;

        &:hover {
          height: $flagCountrySmallMobile;
        }
      }
    }
  }
}
