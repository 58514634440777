.profile-page {
  .switch-profile-page {
    .scope {
      &-field-label {
        color: black;
        font-weight: bold;
      }

      &-progress {
        @mixin absoluteProps() {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          position: absolute;
        }

        &-overlay {
          @include absoluteProps;
          opacity: 0.8;
          background-color: black;
          z-index: $modal_backdrop_z_index;
        }

        &-msg {
          @include absoluteProps;
          font-size: 20px;
          font-weight: bold;
          letter-spacing: 2px;
          z-index: $modal_main_z_index;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
        }
      }

      &-other-accounts {
        .scope-button {
          &:not(:first-of-type) {
            margin-top: 15px;
          }

          &:not(:hover) {
            filter: opacity(0.6);
          }

          &-dot {
            background-color: gray;
          }

          &:hover {
            .scope-button-dot {
              background-color: orange;
            }
          }
        }
      }

      &-button {
        height: auto;
        line-height: normal;
        font-size: 15px;
        min-width: 400px;
        min-height: 60px;
        padding: 5px 0;
        display: inline-flex;
        justify-content: space-between;
        text-transform: none !important;
        align-items: center;
        text-align: center;

        img {
          height: 35px;
          margin: 0 15px;
        }

        &-dot {
          transition: background-color 0.3s ease;
          display: inline-block;
          flex-shrink: 0;
          margin: 0 15px;
          height: 20px;
          width: 20px;
          background-color: #5eba5e;
          border-radius: 50%;
        }

        &-content {
          display: inline-flex;
          align-items: center;
          flex-grow: 1;
        }

        .anonymize {
          color: $danger;
          cursor: pointer;
        }
      }

      &-active-account {
        font-weight: bold;
        cursor: default;

        .scope-button-name {
          cursor: text;
        }
      }

      &-info-text {
        font-size: larger;
      }
    }
  }
}

@media (max-width: 767px) {
  .profile-page {
    .switch-profile-page {
      .scope-button {
        width: 100%;
        min-width: auto;
      }
    }
  }
}
