.site-container._password-update {
  .force-password-update-page {
    flex-direction: column;
    display: flex;

    .scope {
      &-content-header-button-return {
        display: none;
      }

      &-section-title {
        font-weight: normal;
      }
    }
  }

  .footer {
    margin-top: 0;

    hr {
      max-width: none;
    }
  }
}

@media (min-width: $container-width) {
  .site-container._password-update {
    .force-password-update-page {
      max-width: $container-width;
    }
  }
}

@media (min-width: 768px) {
  .site-container._password-update {
    .force-password-update-page {
      min-height: 78vh;
    }
  }
}

@media (max-width: 767px) {
  .site-container._password-update {
    .force-password-update-page {
      .scope-section-title {
        text-align: center;
        border-bottom: 0;
      }
    }

    .footer {
      display: none;
    }
  }
}
