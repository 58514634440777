.check-circle-container {
  display: inline-block;

  $checkCircleSize: 26px;

  .check-circle {
    border-radius: 50%;
    width: $checkCircleSize;
    height: $checkCircleSize;
    background: #e3e3e3;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 0 0 #f2f2f2;
    cursor: pointer;

    &-input {
      display: none;
    }

    svg {
      display: none;
      height: $checkCircleSize - 16px;
      stroke: #ffffff;
    }

    &.checked {
      background: #007aff;

      svg {
        display: inline-block;
      }
    }
  }
}
