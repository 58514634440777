.register-confirmation-popup {
  .modal-content {
    text-align: center;
    font-size: 20px;
    padding: 75px 20px 0;
    height: 330px;
  }

  .title {
    text-transform: uppercase;
    font-weight: 700;
  }

  .description {
    padding-top: 40px;
  }
}

.account-validation-confirmation-popup,
.activation-email-sent-popup-css {
  .modal-content {
    text-align: center;
    padding: 55px 20px 40px;
  }

  .title {
    font-size: 17px;
    text-transform: uppercase;
    font-weight: 700;
  }

  .description {
    font-size: 15px;
    padding-top: 35px;
    width: 260px;
    margin: 0 auto;
  }

  .single {
    font-size: 20px;
    color: #4e4e4e;
  }

  .error {
    font-size: 17px;
    margin: 0 auto;
  }
}

.booking-error-popup {
  .modal-content {
    text-align: center;
    padding: 75px 20px 75px;
  }

  .description {
    font-size: 17px;
    font-weight: 700;
    width: 260px;
    margin: 0 auto;
  }
}

.reset-password-popup {
  .modal-content {
    text-align: center;
    font-size: 20px;
    padding: 75px 20px 0;
    height: 330px;
  }

  .title {
    text-transform: uppercase;
    font-weight: 700;
  }

  .description {
    padding-top: 50px;
  }
}
