.footer {
  .container-language {
    .dropdown-item.active,
    .dropdown-item:active {
      background: #f5f5f5;
    }

    .dropdown,
    .dropup {
      $height: 34px;

      ul {
        li {
          margin: unset;
          display: block;
        }
      }

      .btn {
        display: flex;
        align-items: center;
        line-height: normal;
        height: $height;
        border-radius: 15px;
        background-color: white;
        padding: 0 1em 0 0.8em;
        box-shadow: 0 0 5px #00000033;
        transition: all 0.3s;

        .selected-locale-container {
          display: flex;
          align-items: center;
        }

        i {
          margin-left: 8px;
          font-size: 18px;
          font-weight: bold;
          transition: transform 0.3s;
        }

        span {
          display: inline-flex;
          vertical-align: bottom;
          height: $height;

          .react-svg {
            display: inline-flex;
            margin: 0 4px;
            svg {
              width: 20px;
            }
          }
        }
      }

      &.show {
        .btn {
          i {
            transform: rotate(180deg);
          }
        }
      }

      .dropdown-menu {
        a {
          text-decoration: none;
        }

        li.divider {
          width: 90%;
          height: 1px;
          margin: 0 auto !important;
          text-align: center !important;
        }
      }
    }
  }
}

.container-language {
  .dropup {
    .dropdown-menu {
      margin-bottom: 15px;
      box-shadow: 0 0 12px rgba(0, 0, 0, 0.175);
    }
  }

  .dropdown,
  .dropup {
    .dropdown-menu {
      &.language-menu {
        width: 300px;

        li {
          height: 40px;

          &.dropdown-header {
            text-transform: none;
            padding-top: 10px;
          }

          a {
            display: inline-flex;
            align-items: center;
            width: 100%;

            &:hover {
              font-weight: bold;
            }
          }

          .sub-menu {
            display: flex;
            align-items: center;
            height: 30px;
            line-height: 10px;

            span.react-svg {
              &.flag {
                height: 20px;

                svg {
                  width: 25px;
                  height: auto;
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .footer {
    .container-language {
      .dropdown,
      .dropup {
        .dropdown-menu {
          &.language-menu {
            left: -110px;
          }
        }
      }
    }
  }
}
