.instruction-container {
  .instruction-wrapper {
    float: none !important;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 20px;
    display: inline-block;
    font-size: 0;
    vertical-align: top;

    p {
      font-size: 15px;
      padding: 0 35px;
      min-height: 42px;
      margin-top: 0;

      &.need-margin {
        margin-top: 25px;
      }
    }

    .instruction-image {
      height: 80vw;
      width: 80vw;
      max-width: 250px;
      max-height: 250px;
      display: inline-block;
      background-position: center;
      background-size: cover;
      position: relative;
      margin-bottom: 25px;

      .step-index {
        width: 42px;
        height: 42px;
        box-sizing: content-box;
        font-size: 25px;
        font-weight: 700;
        line-height: 42px;
        text-align: center;
        border-radius: 50%;
        border: 3px solid #fff;
        position: absolute;
        bottom: -24px;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
  }
}

.offer-wrapper {
  background-color: #f8f8f8;

  .offer-container {
    text-align: center;

    .offer-image {
      width: 182px;
      margin-top: 45px;
      margin-bottom: 25px;
      background-color: #fff;
    }

    p {
      font-size: 15px;
    }
  }
}

@media (max-width: 767px) {
  .instruction-container {
    h2 {
      font-size: 2.1rem;
    }
    .instruction-wrapper {
      padding-top: 0;
    }
  }
}
