.business-tabs {
  display: inline-flex;
  background: #eeeeef;
  display: inline-flex;
  position: relative;
  border-radius: 4px 4px 0 0;
  border: 1px solid white;

  &:before {
    position: absolute;
    content: ' ';
    width: 10px;
    height: 10px;
    background: white;
    bottom: -10px;
    left: -1px;
  }

  div {
    display: inline-flex;
    align-items: center;
    padding: 6px 40px;
    position: relative;
    cursor: pointer;

    &:first-child {
      background: white;
      font-weight: bold;
      cursor: default;

      &:before {
        content: ' ';
        width: calc(100% - 80px);
        height: 1px;
        position: absolute;
        top: 40px;
      }
    }

    &:last-child {
      border-left: 2px solid white;
    }

    .react-svg {
      padding-right: 10px;
      display: flex;
    }
  }
}

@media (max-width: 991px) {
  .business-tabs {
    display: none;
  }
}
