.upper-section-wrapper {
  position: relative;
  z-index: 2;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.24);

  &.landing {
    box-shadow: unset;

    nav#user-menu {
      align-items: center;
    }
  }
}

div[class*='_profile_'],
div[class*='_my-bookings'],
div[class*='_faq'] {
  #header {
    #user-menu {
      ul#links {
        display: flex;
        align-items: center;
      }
    }
  }
}

#header {
  padding: 0 20px;
  height: $newHeaderHeight;

  .container-avatar .dropdown-menu {
    z-index: 500;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);

    .dropdown-item.active,
    .dropdown-item:active {
      background-color: inherit;
    }

    .dropdown-divider {
      width: 90%;
      height: 1px;
      border: 0;
      margin: 0 auto;
    }

    a {
      padding: 3px 20px;
      font-weight: 400;
      line-height: 1.42857143;
      white-space: nowrap;
    }
  }

  #user-menu {
    display: flex;
    height: 100%;
    align-items: center;
    flex-direction: row;

    > .logo-container {
      display: inline-flex;
      flex-shrink: 0;
    }

    .li-avatar-wrap {
      flex-shrink: 0;
    }

    > .main-items {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-end;
    }

    a {
      text-decoration: none;
      display: flex;

      &.active {
        font-weight: bold;
      }

      img {
        max-height: $newHeaderHeight - 15px;
      }
    }

    .login {
      &:after {
        content: '';
      }
    }

    .sub-menu {
      color: $dark;
    }

    .link {
      color: $color-white;
      line-height: 16px;
      cursor: pointer;
    }

    ul#links {
      margin-left: auto;

      li {
        margin-left: 2vw;
        display: inline-flex;

        .container-avatar {
          display: inline-flex;
          height: 45px;

          .dropdown {
            ul {
              li {
                margin: unset;
                display: block;
                text-align: right;
              }
            }

            .btn {
              font-weight: normal;

              &::after {
                content: '\f105';
                // use 'important' to prevent issues with browser extensions that change fonts
                font-family: 'icomoon' !important;
                float: right;
                margin-left: 7px;
                font-size: 18px;
                line-height: 43px;
              }
            }

            > button {
              background-color: unset;
              color: $dark;
              text-transform: capitalize;
              font-family: inherit;

              &::after {
                transition: all 0.3s;
                transform: rotate(90deg);
              }
            }

            &.show > button {
              &::after {
                transition: all 0.3s;
                transform: rotate(270deg);
              }
            }

            .dropdown-menu {
              li.divider {
                width: 90%;
                height: 1px;
                margin: 0 auto !important;
                text-align: center !important;
              }
            }
          }

          img {
            height: 100%;
          }
        }
      }
    }

    //home page
    &.landing {
      a {
        color: $color-white !important;
      }

      ul#links {
        display: flex;
        align-items: center;

        li {
          .container-avatar {
            .account-menu {
              &:not(.show) {
                button::after {
                  transform: rotate(90deg);
                }
              }

              > button {
                background-color: unset;
                color: $color-white;
                text-transform: capitalize;
              }
            }
          }
        }
      }
    }
  }
}

#header-mobile {
  height: $newHeaderHeight;
  position: fixed;
  width: 100%;
  background-color: $color-white;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.24);
  z-index: $header_z_index;

  .brand-logo {
    height: 60px;
    padding: 10px 0;
    margin: auto 50px;
  }

  a,
  a.link {
    position: relative;
    height: 60px;
  }

  .burger-icon {
    width: 32px;
    height: 22px;
    position: absolute;
    top: 50%;
    left: 14px;
    transform: rotate(0deg) translate(0, -50%);
    transition: 0.5s ease-in-out;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: #131313;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;

      &:nth-child(1) {
        top: 0px;
        transform-origin: left center;
      }

      &:nth-child(2) {
        top: 10px;
        transform-origin: left center;
      }

      &:nth-child(3) {
        top: 20px;
        transform-origin: left center;
      }
    }

    &.open {
      span {
        &:nth-child(1) {
          transform: rotate(45deg);
          top: -1px;
          left: 8px;
        }

        &:nth-child(2) {
          width: 0%;
          opacity: 0;
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
          top: 22px;
          left: 8px;
        }
      }
    }
  }

  h1 {
    color: $dark;
    font-size: 20px;
    display: inline-block;
    line-height: 60px;
  }

  .mobile-logo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 50px;
  }
}

.nav-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  z-index: $nav_bar_z_index;
  overflow: auto;

  .overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  #menu-mobile {
    background-color: $color-white;
    box-shadow: 3px 0px 5px 0px rgba(0, 0, 0, 0.24);
    width: $mobile_nav_width;
    min-height: 100%;

    ul {
      li {
        .link,
        .nav-item {
          color: $secondary;
          cursor: pointer;
          display: block;
          padding: 15px;
          position: relative;
          text-decoration: none;

          &:after {
            position: absolute;
            content: ' ';
            background-image: url('/assets/images/no-tail-arrow-down.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 50%;
            transform: rotate(-90deg);
            width: 20px;
            height: 20px;
            right: 20px;
            line-height: 20px;
          }
          &.active {
            &:after {
              background-image: url('/assets/images/no-tail-arrow-down-white.svg');
            }
          }
          &.logout {
            color: #ce372e !important;
            font-weight: bold;

            &:after {
              content: '';
            }
          }
        }

        .nav-item {
          text-transform: capitalize;
          font-weight: bold;
          text-align: center;
          line-height: 40px;
        }

        .member-known {
          font-weight: bold;
          text-align: center;
          line-height: 30px;
          padding: 10px 0;
          display: block;
          border-bottom: 1px solid #e1e1e1;
          margin: 0 10px;

          div {
            line-height: normal;
          }

          .member-pic {
            width: 40%;
            display: block;
            margin: 0 auto;
          }

          .status {
            color: #888888;

            &.suspended {
              color: #ff2525 !important;
            }

            &.APPROVED {
              color: #37bc03;
            }

            &.TO_REVIEW {
              color: orange;
            }

            &.NOT_APPROVED {
              color: #ff2525;
            }
          }
        }
      }
    }
  }

  &.mobile-nav-opened {
    display: block;

    .overlay {
      background-color: rgba(0, 0, 0, 0.3);
      animation: fadeInBg 0.4s;
    }

    #menu-mobile {
      animation: slideFromTheLeft 0.4s;

      li.logo {
        text-align: center;
        padding: 60px 0 50px;
        border-bottom: 1px solid $light;
        margin: 0 10px;

        a {
          img {
            max-width: 150px;
          }

          &.logout {
            color: #ce372e;
          }
        }
      }
    }
  }
}
