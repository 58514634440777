.static-page {
  padding: 10px 0 30px;

  .renault-highlight {
    color: #ffcd0d;
    font-size: 18px;
  }

  .heading-lg {
    font-weight: 700;
    margin: 30px 0;
    color: #333;
  }

  .title {
    color: #333;
  }

  .letter-list {
    padding-left: 40px;
  }

  .list-bullet {
    padding-top: 20px;

    > li {
      position: relative;
      padding-left: 40px;
      padding-bottom: 20px;

      &:before {
        content: '\2022';
        position: absolute;
        left: 15px;
        top: 0;
        width: 20px;
        text-align: center;
      }
    }
  }

  .list-line {
    padding-top: 10px;
    > li {
      position: relative;
      padding-left: 40px;
      padding-bottom: 20px;

      &:before {
        content: '-';
        position: absolute;
        left: 15px;
        top: 0;
        width: 20px;
        text-align: center;
      }
    }
  }

  .content {
    max-width: $container-width;
    margin: 0 auto;
    font-size: 14px;

    .text-box {
      padding-bottom: 20px;
      a {
        text-decoration: underline;
        color: #000000;
      }
      div {
        padding-top: 20px;
      }
      strong {
        padding-top: 20px;
        display: block;
        color: #000000;
      }
    }
  }
}
