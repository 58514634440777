.cookie-alert {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background: $accent;
  font-size: 12px;
  transform: translateY(100%);
  animation: slideCookieAlert 0.5s 1s forwards;
  z-index: $cookie_alert_z_index;

  &-actions {
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px 10px;
  }

  &-content {
  }

  &-title {
    font-size: 16px;
    font-weight: 700;
  }
}

@media (max-width: 767px) {
  .cookie-alert {
    &-actions {
      position: static;
      padding: 0 0 10px;
      font-size: 14px;
      text-align: center;
    }

    &-title {
      font-size: 16px;
      font-weight: 700;
    }
  }
}

@keyframes slideCookieAlert {
  100% {
    transform: translateY(0%);
  }
}
