$date-picker-child-margin: 1vw;

$homePageHeightField: 56px;
$bookPageHeightField: 40px;

$labelFontSize: 14px;
$valueFontSize: 14px;

.date-row {
  width: 100%;
  display: flex;
  margin-right: 1vw;

  .date-time-picker-field {
    height: $homePageHeightField;
    width: 100%;
    background-color: rgba(118, 118, 128, 0.12);
    display: flex;
    border-radius: 4px;

    .date-wrapper {
      height: 100%;
      flex-grow: 1;

      .date-picker {
        display: flex;
        position: relative;
        text-align: left;
        height: $homePageHeightField;
        line-height: $homePageHeightField;
        cursor: pointer;
        border: 1px solid transparent;
        box-sizing: border-box;

        &__selected {
          display: inherit;
        }
        //months nav datepicker
        .picker__nav--prev {
          background-image: url('/assets/images/no-tail-arrow-down.svg');
          transform: rotate(90deg);
          background-size: 30%;
          background-position: center;
          background-repeat: no-repeat;
          &:hover {
            background-color: transparent;
          }
          &.picker__nav--disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }

        .picker__nav--next {
          background-image: url('/assets/images/no-tail-arrow-down.svg');
          transform: rotate(-90deg);
          background-size: 30%;
          background-position: center;
          background-repeat: no-repeat;
          &:hover {
            background-color: transparent;
          }
          &.picker__nav--disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }

        .picker__nav--prev:before,
        .picker__nav--next:before {
          border: 0;
        }

        //hover day
        .picker__day--infocus {
          &.picker__day--highlighted {
            background: #fff;
          }

          &:not(.picker__day--disabled):hover {
            background-color: #d9d9d9;
            border-radius: 0;
          }
        }
        //today
        .picker__day--today {
          background-color: #d9d9d9;
          border-radius: 40px;
          &::before {
            border: 0;
          }

          &.picker__day--highlighted {
            background: #d9d9d9;
          }
        }

        // footer buttons
        .picker__button--today {
          background-color: #d9d9d9;
          border-radius: 40px;
          padding: 0.66em 8px 0.66em 1px;
          &::before {
            border: 0;
          }
          &:hover {
            border-color: #000;
          }
          &[disabled] {
            background: white;
            border-color: #ebebeb;
            cursor: not-allowed;
          }
        }
        .picker__button--close {
          padding: 0.66em 8px 0.66em 1px;
          &::before {
            content: '';
          }

          &:hover {
            background-color: #d9d9d9;
            border-color: #000;
          }
        }

        .picker__day--disabled {
          background: white;
          color: #f6f6f6 !important;
          border-color: white;
        }

        .picker__day--outfocus {
          color: #ebebeb;
          &:hover {
            color: black;
            background: #d9d9d9;
          }
        }

        &:hover {
          .label {
            color: $dark;
          }
        }

        .label {
          transition: color 0.3s ease;
          color: #6f6f6f;
          margin-left: $date-picker-child-margin;
          font-size: $labelFontSize;
          text-transform: lowercase;
          @include user-select-none();
        }

        &.date-picker__selected {
          &:before {
            position: absolute;
            content: ' ';
            border-left: 1px solid $primary;
            width: 1px;
            height: 70%;
            top: 10px;
            right: -2px;
          }
        }

        &.pdate-active {
          .label {
            margin-left: 1vw;
          }

          .value {
            margin-left: 1vw;
          }

          &.date-picker__selected {
            border-radius: 4px 0 0 4px;

            &:before {
              border-color: transparent;
            }
          }
        }

        .value {
          font-size: $valueFontSize;
          margin-left: $date-picker-child-margin;
          align-self: center;
          line-height: $homePageHeightField;
          @include user-select-none();
        }

        .picker {
          top: 100%;
          left: 0;

          &__holder {
            outline: none;
            margin-left: -1px;
            width: 280px;
            border-radius: 1px;
            border-color: #d9d9d9;
            margin-top: 4px;
          }
        }

        .placeholder-icon {
          position: absolute;
          top: 0;
          left: 115px;
          font-size: 50px;
          color: #b0b0b0;
        }
      }

      input {
        display: none;
      }
    }

    .time-wrapper {
      width: 56px;
      height: 100%;
      position: relative;
      cursor: pointer;

      .time-picker {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        line-height: $homePageHeightField;
        white-space: nowrap;
        font-size: $valueFontSize;
        text-align: center;
        border: 1px solid transparent;

        .value {
          color: $dark;
          @include user-select-none();
          i {
            font-size: $labelFontSize;
            color: #6f6f6f;
            text-transform: lowercase;
          }
        }

        &:hover {
          .value {
            i {
              transition: 0.3s;
              color: $dark;
            }
          }
        }

        &.ptime-active {
          border-radius: 0 4px 4px 0;
        }
      }

      .picker {
        top: 100%;
        margin-left: -1px;
        &--time {
          .picker__box {
            position: static !important;
          }
        }
        &__holder {
          outline: none;
          margin-top: 4px;
          width: 280px;
          position: absolute;
          left: -100px;
        }
        .picker--focused .picker__list-item--selected,
        .picker--focused .picker__list-item--highlighted,
        .picker__list-item--selected,
        .picker__list-item--selected:hover {
          color: white;
        }
        .picker__list-item {
          position: static !important;

          &.picker__list-item-selected {
            color: white;
            &:hover {
              color: #d9d9d9;
            }
          }
          &:hover {
            background: #d9d9d9;
            border-color: #d9d9d9;
          }
          &.picker__list-item--highlighted {
            color: white;
          }
        }
      }

      .placeholder {
        font-size: 50px;
        color: #b0b0b0;
        line-height: $homePageHeightField;
      }

      input {
        display: none;
      }
    }
  }
}

.search-header {
  .date-row {
    width: unset;

    &:first-child {
      margin-right: 1.5vw;
    }

    .date-time-picker-field {
      border: unset;
      height: $bookPageHeightField;
      background-color: unset;

      .date-wrapper {
        width: 5vw;
        min-width: 80px;
        flex-grow: unset;

        .date-picker {
          height: $bookPageHeightField;
          background-color: rgba(118, 118, 128, 0.12);
          border-radius: 4px;

          &:before {
            border: 0;
          }

          .label {
            font-size: $labelFontSize;
            line-height: $bookPageHeightField;
          }
          .value {
            font-size: $valueFontSize;
            line-height: $bookPageHeightField;
          }
          &.pdate-active.date-picker__selected {
            border-radius: 4px;
          }
        }
      }

      .time-wrapper {
        width: 3vw;
        min-width: 60px;
        background-color: rgba(118, 118, 128, 0.12);
        margin-left: 0.5vw;
        border-left: 0;
        border-radius: 4px;

        &::before {
          content: '';
          border: 0;
        }

        .time-picker {
          height: $bookPageHeightField;

          .value {
            line-height: $bookPageHeightField;
            font-size: $valueFontSize;
            text-transform: lowercase;

            i {
              font-size: $labelFontSize;
              text-transform: lowercase;
            }
          }

          &.ptime-active {
            border-radius: 4px;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .site-container {
    &._book {
      .date-row {
        .date-time-picker-field {
          .date-wrapper .date-picker {
            .picker__holder {
              position: absolute;
              left: 0;
              width: 100%;
            }
          }
          .time-wrapper .time-picker {
            .picker__holder {
              width: calc(100% + 100px);
              position: absolute;
              left: -100px;
            }
          }
        }
      }
    }
    .date-row {
      width: 100%;
      background-color: white;
      padding: 10px;
      border-radius: 4px;
      margin-right: 0;

      &:first-child {
        margin-right: unset;
        margin-bottom: 10px;
      }

      .date-time-picker-field {
        .time-wrapper {
          width: 140px;

          .time-picker {
            .value,
            .value i {
              font-size: 16px;
            }

            .picker__holder {
              width: calc(100% + 80px);
              position: absolute;
              left: -80px;
            }
          }
        }
        .date-wrapper .date-picker {
          .picker__holder {
            position: absolute;
            left: 0px;
            width: 100%;
          }
        }
        .date-wrapper.hasDate .date-picker {
          .picker__holder {
            width: calc(100% + 140px);
          }
        }
        .date-wrapper .date-picker .label,
        .date-wrapper .date-picker.pdate-active .label,
        .date-wrapper .date-picker .value,
        .date-wrapper .date-picker.pdate-active .value {
          margin-left: 16px;
          font-size: 16px;
        }
      }
    }
  }

  .search-booking-homepage-btn {
    width: 100%;
    margin: 0 10px;
    padding: 7px 0;
  }

  .mobileOnly {
    &.search-form {
      .location {
        padding: 0;
        margin-bottom: 0;
      }

      .dates-wrapper {
        margin-top: 10px;

        .date-row {
          padding: 0;
          margin-right: 0;
        }
      }
    }
  }
}
