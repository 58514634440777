@keyframes slideFromTheLeft {
    0% {
        display: none;
        margin-left: -$mobile_nav_width;
    }

    1% {
        display: block;
        margin-left: -$mobile_nav_width;
    }

    100% {
        display: block;
        margin-left: 0;
    }
}

@keyframes fadeInBg {
    0% {
        display: none;
        background-color: transparent;
    }

    1% {
        display: block;
        background-color: transparent;
    }

    100% {
        display: block;
        background-color: rgba(0, 0, 0, 0.3);
    }
}
