.footer {
  background-color: white;
  text-align: center;
  margin-top: $footerMargin;
  color: black;
  flex-shrink: 0;

  $footerMaxWidth: 1200px;

  hr {
    max-width: $footerMaxWidth;
    margin: 0 auto;
  }

  a:hover {
    text-decoration: underline;
  }

  &_subItem.phone-number {
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;

    .react-svg {
      filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.3));
      margin-right: 0.5em;

      svg {
        clip-path: ellipse(32% 48% at 50% 50%);
        height: 1.8em;
      }
    }
  }

  &_subItems > * {
    margin-bottom: 0.2em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &_innerWrapper {
    max-width: $footerMaxWidth;
    margin: 0 auto;
    padding: 30px;
  }

  &_items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 auto;
  }

  .switch_items {
    > *:first-child {
      margin-bottom: 15px;
    }
  }

  &_item {
    margin: 0 auto;
    padding: 0 10px;
    text-align: left;
    display: flex;
    flex-direction: column;

    &_title {
      font-size: 1.1em;
      font-weight: bold;
      margin-bottom: 0.5em;
    }
  }

  &_link {
    color: black;
    text-decoration: none;
  }

  .footer_share {
    display: flex;
    align-items: center;

    .react-svg {
      cursor: pointer;
      margin-left: 0.5em;

      &.twitter {
        svg {
          fill: #1da1f2;
        }
      }

      &.linkedIn {
        svg {
          fill: #0e76a8;
        }
      }

      svg {
        height: 1.4em;
      }
    }
  }
}

@media (max-width: 767px) {
  .footer {
    &_innerWrapper {
      padding: 20px 0;
    }

    &_items {
      flex-direction: column;
      align-items: center;
    }

    &_item {
      width: 100%;
      align-items: center;
      border-bottom: 1px solid #e2e2e2;
      margin: 0;
      padding: 15px 0;

      &_title {
        text-align: center;
      }

      &:first-child {
        padding-top: 0;
        padding-bottom: 18px;
      }

      &:last-child {
        padding-bottom: 0;
        border: 0;
        margin-bottom: 0;
      }
    }
  }
}
