.booking-edit-popup {
  padding-right: 60px;
  padding-left: 60px;
  padding-bottom: 80px;

  .picker {
    &__list {
      padding: 0;
    }

    &__list-item {
      position: static !important;
    }

    &__button--clear {
      display: none;
    }
  }

  .generic-submit-button {
    width: 100%;
  }

  .picker__holder {
    width: 320px;
  }

  .scope-location-wrap {
    margin-bottom: 25px;
  }

  .location-input {
    .mapbox-complete {
      font-size: 1.1em;

      &.visible .mc-input:focus {
        border-bottom: none;
      }

      .mc-dropdown-menu {
        margin-top: 0;
      }

      .mc-input {
        min-height: 45px;
        font-size: 1rem;
      }

      &:not(.visible) {
        .mc-input {
          border-radius: 4px;
          background-color: hsla(240, 4%, 48%, 0.12);
          border-color: transparent;
          font-weight: bold;
          color: #333333;
        }
      }
    }
  }

  .date-picker {
    .picker__holder {
      margin-top: 47px !important;
    }
  }
  .date-picker,
  .time-picker {
    border: 1px solid transparent;

    input {
      display: none;
    }

    .picker--time {
      .picker__box {
        position: static !important;
      }
    }

    .value {
      background: rgba(118, 118, 127, 0.12);
      border-radius: 4px;
      font-size: 1rem;
      padding: 0 14px;
      font-weight: bold;
      min-height: 45px;
      display: flex;
      align-items: center;
      width: 100%;
      white-space: nowrap;
      justify-content: center;
    }
  }

  .scope {
    &-dates-wrap {
      flex-grow: 1;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &-date-time-wrap {
      display: flex;
      margin-bottom: 25px;
      flex-grow: 1;

      &:first-child {
        margin-right: 15px;
      }

      &:last-child {
        margin-left: 15px;
      }

      > div {
        width: 100%;

        &:first-child {
          margin-right: 18px;
        }
      }
    }

    &-title {
      text-align: center;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 40px;
    }
  }
}

.bookingEditPopup.modal {
  overflow: visible;

  .modal-content {
    overflow: visible;
  }
}

@media (max-width: 600px) {
  .booking-edit-popup {
    .scope-date-time-wrap {
      width: 100%;

      &:first-child {
        margin-right: 0;
      }

      &:last-child {
        margin-left: 0;
      }
    }
  }
}
