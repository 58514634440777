.the-vehicles {
  padding: 30px 0 5px;

  .description {
    text-align: center;
    margin-top: 15px;
    font-size: 17px;
  }

  .vehicle-filter {
    margin: 50px auto;
    max-width: 830px;
    width: 100%;

    .vehicle-option {
      input {
        display: none;

        &:checked ~ label {
          background: $accent2;
        }
      }

      label {
        display: block;
        background: $background;
        color: $secondary;
        height: 120px;
        padding-top: 25px;
        text-align: center;
        font-size: 20px;
      }

      .label-icon {
        font-size: 55px;
      }

      .info {
        display: block;
        font-weight: 700;
        margin-top: -15px;
      }

      &-container {
        padding: 0 12px;
        float: left;
        width: 25%;
      }
    }
  }
}

@media (max-width: 767px) {
  .the-vehicles {
    .vehicle-filter {
      margin: 30px auto;

      .vehicle-option {
        label {
          padding-top: 15px;
          font-size: 14px;
          height: 75px;
        }

        .label-icon {
          font-size: 30px;
        }

        .info {
          margin-top: -5px;
        }

        &-container {
          padding: 0 5px;
        }
      }
    }
  }
}

@media (max-width: 479px) {
  .the-vehicles {
    .description {
      font-size: 14px;
    }

    .vehicle-filter {
      .vehicle-option {
        label {
          padding-top: 10px;
          font-size: 11px;
          height: 60px;
        }

        .label-icon {
          font-size: 30px;
        }

        .info {
          margin-top: -8px;
        }
      }
    }
  }
}
