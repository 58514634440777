.booking-success-popup {
  $buttonHeight: 40px;
  $popupMargin: 10px;
  $itemTopPadding: 5px;

  color: #333;

  .react-add-to-calendar {
    width: 100%;
    position: relative;

    .scope-open {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }

    i {
      margin-left: 5px;
    }

    &__dropdown {
      position: absolute;
      bottom: $buttonHeight + $popupMargin;
      width: 100%;
      border: 1px solid #d9d9d9;
      background-color: #fff;
      box-shadow: 0px -2px 15px rgba(0, 0, 0, 0.1);
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;

      ul {
        padding: $itemTopPadding;
      }

      li {
        a {
          width: 100%;
          padding: $itemTopPadding $itemTopPadding * 1.6;
          text-decoration: none;
        }
      }
    }
  }

  .scope {
    &-button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      border-radius: 4px;
      font-size: 14px;
      height: $buttonHeight;
      width: 100%;
      cursor: pointer;
      text-decoration: none;

      &-container {
        display: flex;

        &.sub-class {
          &-left {
            justify-content: flex-end;
          }

          &-right {
            justify-content: flex-start;
          }
        }
      }

      &-main {
        font-weight: bold;
      }

      &-sub {
        border: 1px solid #d9d9d9;
      }
    }
  }
}

@media (max-width: 767px) {
  .booking-success-popup {
    .scope {
      &-button {
        width: 100%;
      }
    }
  }
}
