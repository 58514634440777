.booking-history-details {
  color: #333;

  &.sub-class-pre-booking {
    .booking-history-details-group:not(:first-child) {
      display: none;
    }

    .scope {
      $tutoHeight: 50px;

      &-tutorial-title-container {
        z-index: $modal_main_z_index;
        position: absolute;
        display: flex;
        top: -$tutoHeight;
        height: $tutoHeight;
        align-items: flex-end;
        width: 100%;
        justify-content: space-between;
        padding-bottom: 15px;
      }

      &-grouped-items {
        position: relative;
      }

      &-pre-booking-overlay {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0.8;
        position: absolute;
        background-color: black;
        z-index: $modal_backdrop_z_index;
      }

      &-overlay-button {
        @include getRoundCloseButtonProps;
        margin-left: 15px;
        cursor: pointer;
      }

      &-tutorial-title {
        color: white;
        font-size: 1.2rem;
      }
    }

    .booking-history-details-content {
      &:not(:first-child) {
        width: 100%;
        position: absolute;
        background-color: white;
        z-index: $modal_main_z_index;
        margin-top: 0;
        top: 0;
      }
    }
  }

  .empty-list {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;

    span {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 10px;
    }
  }

  &-cancel-icon {
    padding: 1px;
  }

  .scope {
    &-download-app-msg-container {
      display: none;
    }
  }

  &-action {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 4px;
    min-height: 48px;
    min-width: 83px;
    padding: 0 10px;

    &:hover {
      box-shadow: 1px 1px 5px rgba(128, 128, 128, 0.35);
    }

    &.sub-class-disabled {
      background: #615c5c;
      color: white;
      fill: white;
      cursor: wait;
    }

    &:first-child {
      margin-right: 10px;
    }

    &-grey {
      border: 1px solid #f0f0f0;
      background: #f0f0f0;
    }

    &-white {
      border: 1px solid $tempGrey;
    }

    svg {
      height: 16px;
      margin-bottom: 5px;
    }

    &-name {
      font-size: 12px;
    }
  }

  &-hr {
    margin-top: 15px;
    border-top: 2px dashed #979797;
    margin-bottom: 20px;
  }

  &-group {
    margin-top: 30px;
  }

  &-start {
    &-address {
      font-weight: bold;
    }
  }

  &-plate-number {
    color: #848484;
  }

  &-battery {
    &-container {
      display: flex;
      align-items: center;
    }

    &-parent-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-icon {
      width: 20px;
      margin-left: 10px;
    }

    &-level {
      margin-left: 5px;
      font-size: 13px;
    }
  }

  &-car-title {
    font-size: 16px;
    color: #333;
    font-weight: bold;
  }

  &-picture {
    border-radius: 4px;
    max-height: 300px;
    max-width: 100%;

    &-column {
      padding-right: 15px;
      text-align: center;
    }
  }

  &-day {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }

  &-content {
    margin-top: 30px;
    border: 2px solid #f0f0f0;
    border-radius: 4px;

    &:first-child {
      margin-top: 20px;
    }

    &-body {
      padding: 10px;

      &-text-row {
        margin-top: 10px;
        font-size: 15px;
      }
    }

    &-footer {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f0f0f0;
      min-height: 40px;
      color: #848484;
      font-size: 15px;

      a {
        display: inline-flex;
      }
    }

    &.sub-class {
      &-mobile-msg {
        border: 0;

        .scope {
          &-download-app-msg-container {
            display: block;
            color: #737373;
            font-size: 15px;
            padding: 30px 0 20px;
          }
        }

        .booking-history-details-content {
          &-body {
            border: 2px solid #f0f0f0;
            border-bottom: 0;
            border-radius: 4px 4px 0 0;
          }

          &-footer {
            border-radius: 0 0 4px 4px;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .booking-history-details {
    &-group {
      margin-top: 19px;
    }

    &-picture {
      max-height: 100%;

      &-column {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 0;
        margin-bottom: 10px;
      }
    }

    &-content {
      margin-top: 18px;

      &-body {
        &-text-row {
          margin-top: 8px;
        }
      }

      &:first-child {
        margin-top: 24px;
      }
    }

    .nav-tabs {
      li {
        min-width: 50%;
      }
    }
  }
}
