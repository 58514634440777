.mapbox-complete {
  $closeIconMarginRight: 10px;
  $closeIconSize: 10px;
  $closeIconInnerMargin: 5px;
  $inputIconPadding: $closeIconInnerMargin * 2 + $closeIconSize + $closeIconMarginRight;

  .mc-suggestion {
    padding-left: 0.6em;
  }

  .close-icon {
    visibility: hidden;
    position: absolute;
    right: 0;
    cursor: pointer;
    margin-right: $closeIconMarginRight;
    align-self: center;
  }

  .close-icon.visible {
    visibility: visible;
  }

  /* Fix ReactSVG second wrapper padding bug */
  .close-icon > div {
    display: flex;
  }

  .close-icon svg {
    width: $closeIconSize;
    margin: $closeIconInnerMargin;
  }

  .mapbox-main {
    width: 100%;
  }

  .mc-suggestions {
    overflow-y: auto;
    max-height: 200px;
  }

  .mc-dataset:not(:last-child) {
    margin-bottom: 0.5em;
  }

  .mc-input {
    padding-left: 16px;
  }

  .mapbox-main .mc-input {
    padding-right: 5px + $inputIconPadding;
  }

  .mc-suggestion-icon {
    margin-right: 0.6em;
    height: 100%;
    width: 1.1em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .mc-suggestion-icon svg {
    width: 100%;
    height: auto;
    vertical-align: middle;
  }

  .mc-fixture-icon {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    height: 100%;
  }

  .mc-fixture-icon svg {
    width: 1.5em;
    height: 1.5em;
    margin-right: 0.625em;
    vertical-align: middle;
    fill: #cfcfcf;
  }

  .mc-suggestion:hover {
    background: #f4f4f4;
  }

  .mc-suggestion.mc-cursor {
    background: darken(#f4f4f4, 3%);
  }

  .mc-cursor,
  .mc-suggestion:hover {
    .mc-fixture-icon svg {
      transform: scale(1.1);
      fill: #aaaaaa;
    }
  }

  .mc-dropdown-menu {
    color: #333333;
    background-color: white;
    border: 1px solid #cccccc;

    border-radius: 4px;
    padding: 0.5em;
    box-shadow: 1px 1px 32px -10px rgba(0, 0, 0, 0.62);
  }

  .mc-dropdown-menu .mc-suggestion {
    text-align: left;
    cursor: pointer;
    vertical-align: middle;
    align-items: center;
    position: relative;
  }

  .mc-dropdown-menu .mc-suggestion img {
    height: 2em;
    margin-top: 0.5em;
    margin-right: 10px;
    float: left;
  }

  .mc-dropdown-menu .mc-suggestion small {
    font-size: 0.8em;
    color: #bbbbbb;
  }

  .mc-header {
    font-weight: bold;
    margin-bottom: 0.5em;
    border-bottom: 1px solid #cccccc;
    padding: 5px 16px 10px;
  }

  .mc-footer a {
    display: inline;
  }
}
