.only-sso-form {
  background: white;
  width: 600px;
  margin: 20px auto;
  border-radius: 4px;
  padding: 30px;
  min-height: 120px;
  text-align: center;

  .sc-title {
    margin-bottom: 20px;
    font-size: 1.2em;
  }
}
