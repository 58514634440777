.modal-parent {
  > .outer-close {
    @include getRoundCloseButtonProps;
    position: absolute;
    right: 20px;
    top: 25px;
    z-index: $modal_outer_close_z_index;
    cursor: pointer;
  }
}

.modal-backdrop {
  z-index: $modal_backdrop_z_index;

  &.isError {
    z-index: $error_modal_backdrop_z_index;
  }

  &.recap-booking {
    opacity: 0.8;
  }
}

.modal {
  z-index: $modal_main_z_index;

  .modal-content {
    overflow-x: hidden;
    overflow-y: auto;
  }

  &.isError {
    z-index: $error_modal_main_z_index;
  }

  .close-button-wrap {
    .close {
      position: absolute;
      top: 20px;
      right: 25px;
      font-size: 25px;
      line-height: 1;
      color: #000000;
      outline: none;
      padding: 0;
      cursor: pointer;
      background: transparent;
      border: 0;
      -webkit-appearance: none;
      z-index: 5;

      &:hover,
      &:focus {
        text-decoration: none;
        cursor: pointer;
      }
    }
  }

  &.buttonPositionStatic {
    .modal-content {
      display: flex;
      flex-direction: column;

      .close-button-wrap {
        text-align: right;

        .close {
          margin: 20px;
          position: static;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .modal.legacySize {
    .modal-dialog {
      margin-top: 100px;
      width: 600px;
      max-width: 600px;
    }
  }
}

@media (max-width: 767px) {
  .modal.legacySize {
    .modal-dialog {
      margin: 0;
      width: 100%;
      max-width: 100%;
    }
  }

  .modal-parent {
    .outer-close {
      display: none;
    }
  }
}
