// scss-lint:disable ImportantRule

@mixin margin($size) {
  $unit: #{$size}px;

  .m-#{$size} {
    margin: $unit !important;
  }

  .mt-#{$size} {
    margin-top: $unit !important;
  }

  .mr-#{$size} {
    margin-right: $unit !important;
  }

  .mb-#{$size} {
    margin-bottom: $unit !important;
  }

  .ml-#{$size} {
    margin-left: $unit !important;
  }

  @media (min-width: 992px) {
    .m-md-#{$size} {
      margin: $unit !important;
    }

    .mt-md-#{$size} {
      margin-top: $unit !important;
    }

    .mr-md-#{$size} {
      margin-right: $unit !important;
    }

    .mb-md-#{$size} {
      margin-bottom: $unit !important;
    }

    .ml-md-#{$size} {
      margin-left: $unit !important;
    }
  }

  @media (min-width: 768px) {
    .m-dt-#{$size} {
      margin: $unit !important;
    }

    .mt-dt-#{$size} {
      margin-top: $unit !important;
    }

    .mr-dt-#{$size} {
      margin-right: $unit !important;
    }

    .mb-dt-#{$size} {
      margin-bottom: $unit !important;
    }

    .ml-dt-#{$size} {
      margin-left: $unit !important;
    }
  }

  @media (max-width: 767px) {
    .m-xs-#{$size} {
      margin: $unit !important;
    }

    .mt-xs-#{$size} {
      margin-top: $unit !important;
    }

    .mr-xs-#{$size} {
      margin-right: $unit !important;
    }

    .mb-xs-#{$size} {
      margin-bottom: $unit !important;
    }

    .ml-xs-#{$size} {
      margin-left: $unit !important;
    }
  }
}

@mixin padding($size) {
  $unit: #{$size}px;

  .p-#{$size} {
    padding: $unit !important;
  }

  .pt-#{$size} {
    padding-top: $unit !important;
  }

  .pr-#{$size} {
    padding-right: $unit !important;
  }

  .pb-#{$size} {
    padding-bottom: $unit !important;
  }

  .pl-#{$size} {
    padding-left: $unit !important;
  }

  @media (min-width: 992px) {
    .p-md-#{$size} {
      padding: $unit !important;
    }

    .pt-md-#{$size} {
      padding-top: $unit !important;
    }

    .pr-md-#{$size} {
      padding-right: $unit !important;
    }

    .pb-md-#{$size} {
      padding-bottom: $unit !important;
    }

    .pl-md-#{$size} {
      padding-left: $unit !important;
    }
  }

  @media (min-width: 768px) {
    .p-dt-#{$size} {
      padding: $unit !important;
    }

    .pt-dt-#{$size} {
      padding-top: $unit !important;
    }

    .pr-dt-#{$size} {
      padding-right: $unit !important;
    }

    .pb-dt-#{$size} {
      padding-bottom: $unit !important;
    }

    .pl-dt-#{$size} {
      padding-left: $unit !important;
    }
  }

  @media (max-width: 767px) {
    .p-xs-#{$size} {
      padding: $unit !important;
    }

    .pt-xs-#{$size} {
      padding-top: $unit !important;
    }

    .pr-xs-#{$size} {
      padding-right: $unit !important;
    }

    .pb-xs-#{$size} {
      padding-bottom: $unit !important;
    }

    .pl-xs-#{$size} {
      padding-left: $unit !important;
    }
  }
}

@mixin border($color) {
  .bl {
    border-left: 1px solid $color !important;
  }

  .br {
    border-right: 1px solid $color !important;
  }

  .bt {
    border-top: 1px solid $color !important;
  }

  .bb {
    border-bottom: 1px solid $color !important;
  }

  .ba {
    border: 1px solid $color !important;
  }
}

@mixin border_name($name, $color) {
  // scss-lint:disable NameFormat
  .bl-#{$name} {
    border-left: 1px solid $color !important;
  }

  .br-#{$name} {
    border-right: 1px solid $color !important;
  }

  .bt-#{$name} {
    border-top: 1px solid $color !important;
  }

  .bb-#{$name} {
    border-bottom: 1px solid $color !important;
  }

  .ba-#{$name} {
    border: 1px solid $color !important;
  }
}

// scss-lint:enable ImportantRule

// scss-lint:disable VendorPrefix

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

// scss-lint:enable VendorPrefix

@mixin av-height() {
  display: flex;
  flex-direction: column;
  flex: 1;
}

@mixin flex-col() {
  display: flex;
  flex-direction: column;
}

@mixin flex-center() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin user-select-none() {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

@mixin getRoundCloseButtonProps() {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-image: url('/assets/images/close-circle.svg');
  background-repeat: no-repeat;
  background-color: transparent;
  opacity: 1;
  border: 0;
}

@mixin disabledButtonColors() {
  filter: grayscale(1) opacity(0.7);
}
