.login-msg-form {
  background-color: white;
  width: 600px;
  margin: 20px auto;
  border-radius: 4px;
  padding: 30px;
  min-height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .scope {
    &-title {
      font-size: larger;
      margin-bottom: 20px;
    }
  }
}
