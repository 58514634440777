.login-popup {
  .invite-wrapper {
    .form-content.login-form,
    .form-content {
      .field-wrapper {
        height: 50px;
      }
    }
  }
}

.homepage {
  .search-wrapper {
    .inviteCode {
      svg {
        margin: auto;
        position: absolute;
        top: 0;
        left: 10px;
        bottom: 0;
      }

      input {
        padding: 0 15px 0 45px;
        border-radius: 4px;
      }
    }
  }
}

.invite-wrapper {
  background-color: white;

  .error-msg {
    font-size: 14px;
  }

  &.invite-inline {
    background-color: white;
    width: 600px;
    margin: 20px auto;
    border-radius: 4px;
    padding: 30px;
    min-height: 120px;

    div:first-child {
      width: 100%;
    }

    .form-content {
      display: inline-flex;
      align-items: center;
      width: 100%;
      margin-top: 10px;

      .inline-actions {
        display: flex;
        justify-content: flex-end;

        .btn {
          min-width: unset;
          margin-top: 0;
        }
      }
    }

    .field-wrapper {
      margin-right: 10px;
    }

    .inline-actions {
      display: flex;
      justify-content: flex-end;

      .btn {
        min-width: unset;
        margin-top: 10px;
      }
    }
    .error-msg {
      position: absolute;
    }
    .error-msg.error-resp {
      display: flex;
      align-items: center;
      padding: 0;
      margin-top: 10px;
    }

    .svg-loader {
      margin-top: 10px;
      height: 40px;
      align-items: center;
    }
  }

  .error-msg.error-resp {
    font-size: 1.1em;
    text-align: center;
    height: 50px;
    padding: 45px;
  }
}

.homepage .container .search-wrapper {
  .invite-inline {
    $buttonWidth: 50%;

    .invite-loader {
      margin: 31px 0;
    }

    .error-resp {
      position: static;
      text-align: center;

      span {
        width: 100%;
      }
    }

    .retry-action {
      display: block;

      button {
        min-width: $buttonWidth;
      }
    }

    .invite-title {
      font-size: 1.2em;
      text-align: center;
    }

    .invite-code-form {
      margin-top: 20px;
      position: relative;
      display: block;

      &.hide {
        visibility: hidden;
      }

      .error-msg {
        position: static;
        padding-top: 10px;
        padding-bottom: 0;
        width: 100%;
        text-align: center;
      }

      .inline-actions {
        margin-top: 20px;
        justify-content: center;

        button {
          min-width: $buttonWidth;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .homepage .container .search-wrapper {
    .invite-inline {
      width: 80%;
    }
  }
}

@media (max-width: 600px) {
  .homepage .container .search-wrapper {
    .invite-inline {
      width: 90%;
    }
  }
}

@media (max-width: 500px) {
  .homepage .container .search-wrapper {
    .invite-inline {
      width: 100%;

      .invite-code-form button,
      .retry-action button {
        width: 100%;
      }
    }
  }
}
