/* -------------------------------------------------------
    BASE
---------------------------------------------------------- */

@import './bootstrap/modules';
@import './utils/mixins';
@import './utils/variables';

/* -------------------------------------------------------
    LIBS
---------------------------------------------------------- */

@import './libs/reset';
@import './libs/geosuggest';
@import './libs/custom-scroll';
@import './libs/toggle-switch';
@import './libs/check-circle';
@import './libs/flash-message';
@import './libs/spinner';
@import './libs/phone-number';
@import './libs/app-spinner';
@import './libs/calendar-button';

/* -------------------------------------------------------
    UTILS
---------------------------------------------------------- */

@import './utils/fonts';
@import './utils/bootstrap-utils';
@import './utils/bootstrap-grid';
@import './utils/general';
@import './utils/partials';
@import './utils/boilerplate';
@import './utils/animations';
@import './utils/bootstrap-dropdown';

/* -------------------------------------------------------
    COMPONENTS
---------------------------------------------------------- */

@import './components/form';
@import './components/buttons';
@import './components/card';
@import './components/modal';
@import './components/table';
@import './components/bookingHistory';
@import './components/bookingEditList';
@import './components/bookingHistoryDetails';
@import './components/newForms';
@import './components/account/credentials';
@import './components/account/payment';
@import './components/account/registerSuccess';
@import './components/account/switchProfile';
@import './components/account/phoneNumber';
@import './components/account/moreInfo';
@import './components/searchBookingsForm';
@import './components/inviteCodeForm';
@import './components/onlySsoForm';
@import './components/loginMsgForm';
@import './components/personSlider';
@import './components/businessTabs';
@import './components/mapbox-complete/index';

/* -------------------------------------------------------
    PAGES
---------------------------------------------------------- */

@import './pages/forcePasswordUpdate';
@import './pages/register';
@import './pages/homepage';
@import './pages/howitworks';
@import './pages/keycloak';
@import './pages/searchBookings';
@import './pages/theVehicles';
@import './pages/professional';
@import './pages/faq';
@import './pages/static';
@import './pages/welcomeMessage';
@import './pages/selectBrandCompany';
@import './pages/creation';
@import './pages/creation/activation';

/* -------------------------------------------------------
    ACCOUNT
---------------------------------------------------------- */

@import './pages/account/myAccount';
@import './pages/account/legalInfo';

/* -------------------------------------------------------
    PARTIALS
---------------------------------------------------------- */

@import './partials/datePicker/default.css';
@import './partials/datePicker/default.date.css';
@import './partials/datePicker/default.time.css';
@import './partials/datePicker/override';
@import './partials/header';
@import './partials/tabletBurger';
@import './partials/footer';
@import './partials/advantages';
@import './partials/mobilebanner';
@import './partials/datetimepicker';
@import './partials/mapPopup';
@import './partials/cookieAlert';
@import './partials/personalDocumentsUpload';
@import './partials/passwordToggle';
@import './partials/promoInput';
@import './partials/languageSwitch';
@import './partials/brandSwitch';

/* -------------------------------------------------------
    POPUPS
---------------------------------------------------------- */

@import './partials/popups/login';
@import './partials/popups/car';
@import './partials/popups/confirmation';
@import './partials/popups/redirectMobile';
@import './partials/popups/bookingCancel';
@import './partials/popups/bookingEdit';
@import './partials/popups/bookingSuccess';
@import './partials/popups/subscribeForm';
@import './partials/popups/countrySelector';
@import './partials/popups/anonymConfirm';
