$profileFieldContainerMarginBottom: 30px;
$profileFieldContainerMarginBottomMobile: 20px;

.site-container[class*='_profile'] {
  .footer {
    margin-top: 0;

    hr {
      max-width: none;
    }
  }
}

.profile-page {
  min-height: calc(100vh - #{$newHeaderHeight});
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.24);
  display: flex;
  max-width: $newPagesWidth;
  margin: 0 auto;

  .pickerModal {
    font-size: 10px;

    &__holder {
      outline: none;
    }

    &__frame {
      max-width: 466px;
    }
  }

  .scope {
    &-select-menu-item {
      display: flex;
      margin-top: 10vh;
      justify-content: center;
      font-size: 1.5rem;
    }

    &-document {
      &-inner {
        &:first-child {
          margin-bottom: $profileFieldContainerMarginBottom;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-info-text {
      font-size: 15px;
    }

    &-check-circle {
      &-container {
        display: flex;
        align-items: center;
      }

      &-info {
        margin-left: 15px;
        font-size: 14px;

        a {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }

    &-boolean {
      &-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &-info {
        font-size: 15px;
        color: #848484;
        width: 90%;
      }
    }

    &-submit-button {
      cursor: pointer;
      height: 45px;
      min-width: 300px;
      border: 1px solid transparent;
      outline: none;
      font-size: 15px;
      font-weight: bold;

      &.sub-class-disabled {
        @include disabledButtonColors;
        cursor: wait;
      }

      &:disabled {
        cursor: not-allowed;
      }
    }

    &-field {
      &-side-select {
        display: flex;

        .sub-class-disabled {
          margin-right: 5px;
        }

        .new-design-select {
          &-container {
            width: auto;
            margin-right: 18px;
          }

          &-styled {
            justify-content: center;
          }
        }
      }

      &-label {
        font-size: 15px;
        margin-bottom: 10px;
        color: #848484;
      }

      &-container {
        margin-bottom: $profileFieldContainerMarginBottom;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &-info {
        margin-top: 10px;
        font-size: 13px;
      }
    }

    &-section {
      margin-top: 30px;

      &-title {
        margin-bottom: 30px;
        font-weight: bold;
        border-top: 2px dashed #979797;
        padding-top: 30px;
        font-size: 18px;
      }

      &:first-child {
        margin-top: 0;

        .scope-section-title {
          border-top: 0;
          padding-top: 0;
        }
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding: 40px 55px 30px;

      &-header {
        display: flex;
        align-items: center;
        margin-bottom: 40px;

        &-button-return {
          display: none;
        }

        &-picture {
          width: 106px;
          margin-right: 20px;
        }

        &-title {
          font-size: 26px;
          font-weight: bold;
        }
      }

      &-body {
        margin-bottom: 40px;
      }

      &-footer.sub-dual-submit {
        display: flex;
        width: 100%;
        gap: 20px;

        .scope-submit-button {
          flex-grow: 1;
        }
      }
    }

    &-menu {
      flex-shrink: 0;
      width: 320px;
      border-right: 2px solid #e1e1e1;

      &-header {
        padding: 20px 20px;
        border-bottom: 2px solid #e1e1e1;

        &-company-name {
          display: inline-block;
        }

        &-active-profile {
          font-size: 14px;
          display: block;
          text-align: center;
        }

        &-user-container {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &-profile-container {
          margin-top: 15px;
        }

        &-switch-container {
          margin-top: 10px;
        }

        &-icon {
          width: 85px;
          margin-right: 20px;
        }

        &-text-container {
          font-size: 16px;
          display: flex;
          flex-direction: column;
        }

        &-user-name {
          padding-bottom: 5px;
        }

        &-status {
          color: #888888;
          font-weight: bold;
          font-size: 15px;

          &.suspended {
            color: #ff2525 !important;
          }

          &.sub-class {
            &-APPROVED {
              color: #37bc03;
            }

            &-TO_REVIEW {
              color: orange;
            }

            &-NOT_APPROVED {
              color: #ff2525;
            }
          }
        }
      }

      &-body {
        padding: 30px 20px 30px 50px;
        font-size: 15px;
        display: flex;
        flex-direction: column;

        &-link-container {
          margin-bottom: 15px;

          a {
            display: inline-block;
            border-bottom: 3px solid transparent;
            padding-bottom: 2px;
            text-decoration: none;

            &.active {
              font-weight: bold;
            }
          }

          svg {
            display: none;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .profile-page {
    .scope {
      &-document {
        &-container {
          display: flex;
        }

        &-inner {
          width: 50%;

          &:only-child {
            width: 100%;
          }

          &:first-child {
            margin-bottom: 0;
            margin-left: 0;
            margin-right: 20px;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .profile-page .scope {
    &-content {
      padding: 30px;
    }

    &-menu {
      width: 290px;

      &-header {
        padding: 20px 15px;
      }

      &-body {
        padding-left: 35px;
      }
    }
  }
}

@media (min-width: 768px) {
  .site-container[class*='_profile'] {
    .profile-page {
      min-height: 78vh;
    }
  }
}

@media (max-width: 767px) {
  .site-container[class*='_profile'] {
    .footer {
      display: none;
    }
  }

  .profile-page {
    padding-bottom: $profilePageMobileFooterHeight;
    width: 100%;

    .scope {
      &-document {
        &-inner {
          &:first-child {
            margin-bottom: $profileFieldContainerMarginBottomMobile;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &-check-circle {
        &-container {
          margin-left: 10px;
        }

        &-info {
          font-size: 12px;
        }
      }

      &-info-text {
        font-size: 14px;
      }

      &-boolean-info {
        font-size: 14px;
      }

      &-submit-button {
        flex-grow: 1;
        font-size: 13px;
        min-width: auto;
      }

      &-content {
        padding: 25px 10px;

        &:not(.active) {
          display: none;
        }

        &-body {
          margin-bottom: 0;
        }

        &-footer {
          z-index: 10;
          background: white;
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.24);
          gap: 20px;
          padding: 0 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: $profilePageMobileFooterHeight;
        }

        &-header {
          margin-bottom: 30px;
          flex-direction: column-reverse;

          &-title {
            font-size: 16px;
          }

          &-title-container {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
          }

          &-button-return {
            left: 0;
            display: inline-block;
            position: absolute;
            padding: 5px;

            svg {
              width: 18px;
            }
          }

          &-picture {
            margin-right: 0;
            margin-top: 15px;
          }
        }
      }

      &-menu {
        border: 0;
        width: 100%;

        &:not(.active) {
          display: none;
        }

        &-body {
          padding: 0 10px 10px;
          font-size: 14px;

          &-link-container {
            padding: 5px 0;
            margin-bottom: 0;
            min-height: 44px;
            display: flex;
            align-items: center;
            border-top: 2px solid #f4f4f4;

            &:last-child {
              border-bottom: 2px solid #f4f4f4;
            }

            svg {
              height: 11px;
              display: inline-block;
              margin-left: 10px;
            }

            a {
              padding-bottom: 0;
              border: 0;

              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;

              &.active {
                font-weight: normal;
                border-color: transparent;
              }
            }
          }
        }

        &-header {
          border-bottom: none;
          padding: 25px 10px;

          &-switch-container {
            text-align: center;
          }

          &-user-container {
            flex-direction: column;
            justify-content: center;
          }

          &-icon {
            margin-right: 0;
            margin-bottom: 25px;
          }

          &-text-container {
            align-items: center;
          }
        }
      }

      &-field {
        &-side-select {
          .new-design-select-container {
            margin-right: 10px;
          }
        }

        &-label {
          margin-bottom: 6px;
          font-size: 14px;
        }

        &-info {
          margin-top: 6px;
          font-size: 11px;
        }

        &-container {
          margin-bottom: $profileFieldContainerMarginBottomMobile;
        }
      }

      &-section {
        &-title {
          margin-bottom: 25px;
          border-top: none;
          padding-bottom: 5px;
          border-bottom: 2px dashed #979797;
          padding-top: 0;
          font-size: 14px;
        }
      }
    }
  }
}
