@mixin container-params() {
  width: 100%;
  margin-top: 10px;
  height: 36px;
  border: 1px solid #cccccc;
  color: #333333;
}

.search-form {
  background-color: $color-white;
  border-radius: 4px;
}

.filters-panel.base-filters-panel {
  width: 100%;
}
