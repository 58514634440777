.site-container._profile_legal-info {
  .profile-page .scope {
    @media (max-width: 767px) {
      &-section-title {
        display: flex;
        justify-content: center;
      }

      &-field-container {
        display: flex;
        justify-content: center;
      }
    }

    @media (min-width: 768px) {
      &-field-container {
        margin-bottom: 15px;
      }

      &-info-text {
        display: inline-block;

        a {
          margin-left: 20px;
          display: list-item;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
