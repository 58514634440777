.register-success-page {
  > .scope-content-header {
    justify-content: center;

    .scope-content-header-picture {
      width: auto;
      margin: 0;
    }
  }

  > .scope-content-body {
    .scope-info-title {
      text-align: center;
      font-size: 19px;
      margin-bottom: 17px;
      font-weight: bold;
    }

    .scope-info-text {
      text-align: center;
    }
  }

  > .scope-content-footer {
    text-align: center;
  }
}

@media (min-width: 768px) {
  .register-success-page {
    > .scope-content-body {
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@media (max-width: 767px) {
  .register-success-page {
    > .scope-content-body {
      .scope-info-title {
        font-size: 17px;
      }
    }
  }
}
