.advantages-section {
  padding-top: 35px;

  .section-icon {
    font-size: 70px;
  }

  .section-description {
    font-size: 16px;
    color: #676767;
    padding-top: 15px;
    height: 70px;
  }

  &.homepage-type {
    padding-top: 45px;

    .title {
      display: none;
    }
  }
}
