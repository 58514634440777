.mapbox-complete .mc-input,
.mapbox-complete .mc-hint {
  width: 100%;
  padding-right: 35px;
  padding-left: 16px;
  line-height: 40px;
  height: 40px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  outline: none;
  font: inherit;
  appearance: none;
  -webkit-appearance: none;
  box-sizing: border-box;
}

.mapbox-complete .mc-input::-webkit-search-decoration {
  -webkit-appearance: none;
}

.mapbox-complete .mc-input::-ms-clear {
  display: none;
}

.mapbox-complete .mc-input:hover ~ .mc-input-icon svg,
.mapbox-complete .mc-input:focus ~ .mc-input-icon svg,
.mapbox-complete .mc-input-icon:hover svg {
  fill: #aaaaaa;
}

.mapbox-complete .mc-dropdown-menu {
  width: 100%;
  background: white;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  margin-top: 3px;
  overflow: hidden;
}

.mapbox-complete .mc-suggestion {
  cursor: pointer;
  height: 2.875em;
  line-height: calc(2.875em / 2);
  padding-left: 1em;
  padding-right: 0.4em;
  overflow: hidden;
}

.mapbox-complete .mc-suggestion {
  em,
  mark {
    font-weight: bold;
    font-style: normal;
  }
}

.mapbox-complete .mc-name {
  vertical-align: middle;
}

.mapbox-complete .mc-address {
  vertical-align: middle;
  font-size: smaller;
  margin-left: 12px;
  color: #aaaaaa;
}

.mapbox-complete .mc-suggestion-icon {
  margin-right: 10px;
  vertical-align: middle;
}

.mapbox-complete .mc-suggestion-icon svg {
  fill: #cfcfcf;
  -webkit-transform: none;
  transform: none;
}

.mapbox-complete .mc-input-icon {
  border: 0;
  background: transparent;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 16px;
  outline: none;
}

.mapbox-complete .mc-input-icon.mc-icon-pin {
  cursor: initial;
}

.mapbox-complete .mc-input-icon svg {
  fill: #cfcfcf;
  position: static;
  top: 50%;
  right: 0;
  -webkit-transform: none;
  transform: none;
}

.mapbox-complete {
  .mc-cursor,
  .mc-suggestion:hover {
    .mc-suggestion-icon svg {
      -webkit-transform: none;
      transform: scale(1.1);
      fill: #aaaaaa;
    }
  }
}

.mapbox-complete .mc-footer {
  opacity: 0.8;
  text-align: right;
  padding: 0.5em 1em 0.5em 0;
  font-size: 12px;
  line-height: 12px;
}

.mapbox-complete .mc-footer a {
  color: inherit;
  text-decoration: none;
}

.mapbox-complete .mc-footer a svg {
  vertical-align: middle;
}

.mapbox-complete .mc-footer:hover {
  opacity: 1;
}
