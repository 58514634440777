/* ==========================================================================
   $BASE-DATE-PICKER
   ========================================================================== */
/**
 * The picker box.
 */
.pickerModal__box {
  padding: 0 1em;
}
/**
 * The header containing the month and year stuff.
 */
.pickerModal__header {
  text-align: center;
  position: relative;
  margin-top: 0.75em;
}
/**
 * The month and year labels.
 */
.pickerModal__month,
.pickerModal__year {
  font-weight: 500;
  display: inline-block;
  margin-left: 0.25em;
  margin-right: 0.25em;
}
.pickerModal__year {
  color: #999;
  font-size: 0.8em;
  font-style: italic;
}
/**
 * The month and year selectors.
 */
.pickerModal__select--month,
.pickerModal__select--year {
  border: 1px solid #b7b7b7;
  height: 2em;
  padding: 0.5em;
  margin-left: 0.25em;
  margin-right: 0.25em;
}
@media (min-width: 24.5em) {
  .pickerModal__select--month,
  .pickerModal__select--year {
    margin-top: -0.5em;
  }
}
.pickerModal__select--month {
  width: 35%;
}
.pickerModal__select--year {
  width: 22.5%;
}
.pickerModal__select--month:focus,
.pickerModal__select--year:focus {
  border-color: #0089ec;
}
/**
 * The month navigation buttons.
 */
.pickerModal__nav--prev,
.pickerModal__nav--next {
  position: absolute;
  padding: 0.5em 1.25em;
  width: 1em;
  height: 1em;
  box-sizing: content-box;
  top: -0.25em;
}
@media (min-width: 24.5em) {
  .pickerModal__nav--prev,
  .pickerModal__nav--next {
    top: -0.33em;
  }
}
.pickerModal__nav--prev {
  left: -1em;
  padding-right: 1.25em;
}
@media (min-width: 24.5em) {
  .pickerModal__nav--prev {
    padding-right: 1.5em;
  }
}
.pickerModal__nav--next {
  right: -1em;
  padding-left: 1.25em;
}
@media (min-width: 24.5em) {
  .pickerModal__nav--next {
    padding-left: 1.5em;
  }
}
.pickerModal__nav--prev:before,
.pickerModal__nav--next:before {
  content: ' ';
  border-top: 0.5em solid transparent;
  border-bottom: 0.5em solid transparent;
  border-right: 0.75em solid #000;
  width: 0;
  height: 0;
  display: block;
  margin: 0 auto;
}
.pickerModal__nav--next:before {
  border-right: 0;
  border-left: 0.75em solid #000;
}
.pickerModal__nav--prev:hover,
.pickerModal__nav--next:hover {
  cursor: pointer;
  color: #000;
  background: #b1dcfb;
}
.pickerModal__nav--disabled,
.pickerModal__nav--disabled:hover,
.pickerModal__nav--disabled:before,
.pickerModal__nav--disabled:before:hover {
  cursor: default;
  background: none;
  border-right-color: #f5f5f5;
  border-left-color: #f5f5f5;
}
/**
 * The calendar table of dates
 */
.pickerModal__table {
  text-align: center;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  font-size: inherit;
  width: 100%;
  margin-top: 0.75em;
  margin-bottom: 0.5em;
}
@media (min-height: 33.875em) {
  .pickerModal__table {
    margin-bottom: 0.75em;
  }
}
.pickerModal__table td {
  margin: 0;
  padding: 0;
}
/**
 * The weekday labels
 */
.pickerModal__weekday {
  width: 14.285714286%;
  font-size: 0.75em;
  padding-bottom: 0.25em;
  color: #999;
  font-weight: 500;
  /* Increase the spacing a tad */
}
@media (min-height: 33.875em) {
  .pickerModal__weekday {
    padding-bottom: 0.5em;
  }
}
/**
 * The days on the calendar
 */
.pickerModal__day {
  padding: 0.32em 0;
  font-weight: 200;
  border: 1px solid transparent;
}
.pickerModal__day--today {
  position: relative;
}
.pickerModal__day--today:before {
  content: ' ';
  position: absolute;
  top: 2px;
  right: 2px;
  width: 0;
  height: 0;
  border-top: 0.5em solid #0059bc;
  border-left: 0.5em solid transparent;
}
.pickerModal__day--disabled:before {
  border-top-color: #aaa;
}
.pickerModal__day--outfocus {
  color: #ddd;
}
.pickerModal__day--infocus:hover,
.pickerModal__day--outfocus:hover {
  cursor: pointer;
  color: #000;
  background: #b1dcfb;
}
.pickerModal__day--highlighted {
  border-color: #0089ec;
}
.pickerModal__day--highlighted:hover,
.pickerModal--focused .pickerModal__day--highlighted {
  cursor: pointer;
  color: #000;
  background: #b1dcfb;
}
.pickerModal__day--selected,
.pickerModal__day--selected:hover,
.pickerModal--focused .pickerModal__day--selected {
  background: #0089ec;
  color: #fff;
}
.pickerModal__day--disabled,
.pickerModal__day--disabled:hover,
.pickerModal--focused .pickerModal__day--disabled {
  background: #f5f5f5;
  border-color: #f5f5f5;
  color: #ddd;
  cursor: default;
}
.pickerModal__day--highlighted.pickerModal__day--disabled,
.pickerModal__day--highlighted.pickerModal__day--disabled:hover {
  background: #bbb;
}
/**
 * The footer containing the "today", "clear", and "close" buttons.
 */
.pickerModal__footer {
  text-align: center;
}
.pickerModal__button--today,
.pickerModal__button--clear,
.pickerModal__button--close {
  border: 1px solid #fff;
  background: #fff;
  font-size: 0.8em;
  padding: 0.66em 0;
  font-weight: bold;
  width: 33%;
  display: inline-block;
  vertical-align: bottom;
}
.pickerModal__button--today:hover,
.pickerModal__button--clear:hover,
.pickerModal__button--close:hover {
  cursor: pointer;
  color: #000;
  background: #b1dcfb;
  border-bottom-color: #b1dcfb;
}
.pickerModal__button--today:focus,
.pickerModal__button--clear:focus,
.pickerModal__button--close:focus {
  background: #b1dcfb;
  border-color: #0089ec;
  outline: none;
}
.pickerModal__button--today:before,
.pickerModal__button--clear:before,
.pickerModal__button--close:before {
  position: relative;
  display: inline-block;
  height: 0;
}
.pickerModal__button--today:before,
.pickerModal__button--clear:before {
  content: ' ';
  margin-right: 0.45em;
}
.pickerModal__button--today:before {
  top: -0.05em;
  width: 0;
  border-top: 0.66em solid #0059bc;
  border-left: 0.66em solid transparent;
}
.pickerModal__button--clear:before {
  top: -0.25em;
  width: 0.66em;
  border-top: 3px solid #e20;
}
.pickerModal__button--close:before {
  content: '\D7';
  top: -0.1em;
  vertical-align: top;
  font-size: 1.1em;
  margin-right: 0.35em;
  color: #777;
}
.pickerModal__button--today[disabled],
.pickerModal__button--today[disabled]:hover {
  background: #f5f5f5;
  border-color: #f5f5f5;
  color: #ddd;
  cursor: default;
}
.pickerModal__button--today[disabled]:before {
  border-top-color: #aaa;
}

/* ==========================================================================
   $DEFAULT-DATE-PICKER
   ========================================================================== */
