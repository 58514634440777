:root,
[data-bs-theme='light'] {
  /*--bs-success: #198754; !* validation.scss *! */
  /*--bs-danger: #dc3545; !* validation.scss *!*/
  /*--bs-primary-rgb: 13, 110, 253; !* (text | utils).scss *!*/
  /*--bs-secondary-rgb: 108, 117, 125; !* (text | utils).scss *!*/
  /*--bs-success-rgb: 25, 135, 84; !* (text | utils | validation).scss *!*/
  /*--bs-info-rgb: 13, 202, 240; !* (text | utils).scss *!*/
  /*--bs-warning-rgb: 255, 193, 7; !* (text | utils).scss *!*/
  /*--bs-danger-rgb: 220, 53, 69; !* (text | utils | validation).scss *!*/
  /*--bs-light-rgb: 248, 249, 250; !* (text | utils).scss *!*/
  /*--bs-dark-rgb: 33, 37, 41; !* (text | utils).scss *!*/
  --bs-primary-text-emphasis: #052c65; /* (accordion | alert | list-group | utils).scss */
  /*--bs-secondary-text-emphasis: #2b2f32; !* (alert | list-group | utils).scss *!*/
  /*--bs-success-text-emphasis: #0a3622; !* (alert | list-group | utils).scss *!*/
  /*--bs-info-text-emphasis: #055160; !* (alert | list-group | utils).scss *!*/
  /*--bs-warning-text-emphasis: #664d03; !* (alert | list-group | utils).scss *!*/
  /*--bs-danger-text-emphasis: #58151c; !* (alert | list-group | utils).scss *!*/
  /*--bs-light-text-emphasis: #495057; !* (alert | list-group | utils).scss *!*/
  /*--bs-dark-text-emphasis: #495057; !* (alert | list-group | utils).scss *!*/
  --bs-primary-bg-subtle: #cfe2ff; /* (accordion | alert | list-group | utils).scss */
  /*--bs-secondary-bg-subtle: #e2e3e5; !* (alert | list-group | utils).scss *!*/
  /*--bs-success-bg-subtle: #d1e7dd; !* (alert | list-group | utils).scss *!*/
  /*--bs-info-bg-subtle: #cff4fc; !* (alert | list-group | utils).scss *!*/
  /*--bs-warning-bg-subtle: #fff3cd; !* (alert | list-group | utils).scss *!*/
  /*--bs-danger-bg-subtle: #f8d7da; !* (alert | list-group | utils).scss *!*/
  /*--bs-light-bg-subtle: #fcfcfd; !* (alert | list-group | utils).scss *!*/
  /*--bs-dark-bg-subtle: #ced4da; !* (alert | list-group | utils).scss *!*/
  /*--bs-primary-border-subtle: #9ec5fe; !* (alert | list-group | utils).scss *!*/
  /*--bs-secondary-border-subtle: #c4c8cb; !* (alert | list-group | utils).scss *!*/
  /*--bs-success-border-subtle: #a3cfbb; !* (alert | list-group | utils).scss *!*/
  /*--bs-info-border-subtle: #9eeaf9; !* (alert | list-group | utils).scss *!*/
  /*--bs-warning-border-subtle: #ffe69c; !* (alert | list-group | utils).scss *!*/
  /*--bs-danger-border-subtle: #f1aeb5; !* (alert | list-group | utils).scss *!*/
  /*--bs-light-border-subtle: #e9ecef; !* (alert | list-group | utils).scss *!*/
  /*--bs-dark-border-subtle: #adb5bd; !* (alert | list-group | utils).scss *!*/
  /*--bs-white-rgb: 255, 255, 255; !* utils.scss *!*/
  /*--bs-black-rgb: 0, 0, 0; !* utils.scss *!*/
  /*--bs-font-sans-serif: 'MainFont', Palatino, serif;  !* tooltip.scss *!*/
  /*--bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;  !* (base | utils).scss *!*/
  /*--bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));  !* (btn | utils).scss *!*/
  /*--bs-body-font-family: var(--bs-font-sans-serif);  !* base.scss *!*/
  /*--bs-body-font-size: 1rem; !* base.scss *!*/
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

[data-bs-theme='dark'] {
  color-scheme: dark;
  --bs-body-color: #adb5bd;
  --bs-body-color-rgb: 173, 181, 189;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(173, 181, 189, 0.75);
  --bs-secondary-color-rgb: 173, 181, 189;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(173, 181, 189, 0.5);
  --bs-tertiary-color-rgb: 173, 181, 189;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}
