.site-container._faq {
  .faq {
    min-height: 73vh;
  }
}

.faq-popup-modal {
  .modal-content {
    border-radius: 10px;
  }
}

.faq {
  .accordion-button:focus {
    box-shadow: revert;
  }

  h2 {
    margin: 0;
    font-size: 1em;
  }

  .accordion-item {
    border: 0;

    .accordion-header .accordion-button {
      border-radius: 0;
    }
  }

  .accordion-button {
    color: #353535;

    &::after {
      background-image: none;
    }
  }

  .section1 {
    padding: 50px 0 35px;

    .heading-lg {
      font-weight: normal;
    }

    .description {
      text-align: center;
      font-size: 15px;
      max-width: 650px;
      margin: 15px auto 0;
    }
  }

  .section2 {
    @media (min-width: 992px) {
      .container {
        width: 800px;
      }
    }

    .accordion-level1 {
      > .accordion-item {
        > .accordion-header button {
          background-color: transparent;
          position: relative;
          display: block;
          font-size: 16px;
          text-transform: lowercase;
          padding: 14px 30px;
          border-bottom: 1px solid #c3c3c3;
          cursor: pointer;
          overflow: hidden;

          &::after {
            content: '\f107';
            // use 'important' to prevent issues with browser extensions that change fonts
            font-family: 'icomoon' !important;
            position: absolute;
            right: 20px;
            font-size: 20px;
            top: 10px;
            transition: all ease 0.3s;
            transform: rotate(0deg);
          }

          &[aria-expanded='true'] {
            &::after {
              transform: rotate(180deg);
            }
          }
        }

        > .panel-collapse .panel-body {
          padding: 35px 0 30px;
        }
      }
    }

    .accordion-level2 {
      > .accordion-item {
        > .accordion-header button {
          display: block;
          font-size: 14px;
          padding: 13px 30px;
          background: #e7e7e7;
          transition: 0.1s;
          margin-bottom: 10px;
          cursor: pointer;
          color: #1d1d1d;
        }

        > .panel-collapse .panel-body {
          padding: 15px 0 30px;
        }
      }
    }
  }

  .section3 {
    padding: 30px 0 0;
    font-size: 0;

    .description {
      display: inline-block;
      font-size: 16px;
      width: calc(100% - 230px);
      vertical-align: middle;
    }

    .btn {
      width: 230px;
      vertical-align: middle;
    }
  }
}

@media (max-width: 767px) {
  .site-container._faq {
    .faq {
      min-height: 68vh;
    }
  }

  .faq {
    .section3 {
      .description {
        display: block;
        font-size: 16px;
        width: 100%;
        margin-bottom: 15px;
      }
    }
  }
}
