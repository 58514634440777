.account-credentials-page {
  .scope {
    &-current-email {
      &-icon {
        height: 22px;
        margin-right: 10px;
      }

      &-text {
        font-size: 13px;
        font-weight: bold;
      }

      &-container {
        margin-left: 10px;
        margin-top: 15px;
        display: flex;
        align-items: center;
      }
    }
  }
}
