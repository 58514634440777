$mobileBreakPoint: 768px;
$tabletBreakPoint: 1550px;
$dropHeight: 200px;
$dropWidth: 150px;

#header {
  .tablet-profile-link {
    position: absolute;
    visibility: hidden;
  }

  .booking-page {
    .menu-icon {
      cursor: pointer;
      float: right;
      margin-left: auto;
      padding: 15px 10px;
      position: relative;
      user-select: none;
      display: none;

      .navicon {
        background: #333;
        display: block;
        height: 2px;
        position: relative;
        transition: background 0.2s ease-out;
        width: 18px;

        &:before,
        &:after {
          background: #333;
          content: '';
          display: block;
          height: 100%;
          position: absolute;
          transition: all 0.2s ease-out;
          width: 100%;
        }

        &:before {
          top: 5px;
        }
        &:after {
          top: -5px;
        }
      }
    }

    // menu btn

    .menu-btn {
      display: none;
    }

    .menu-btn:checked ~ ul#links {
      max-height: $dropHeight;
      min-width: $dropWidth;
      height: 120px;

      &.set-height {
        height: $dropHeight;
      }

      .login.btn.primary-btn {
        width: 100%;
      }
    }

    .menu-btn:checked ~ .menu-icon .navicon {
      background: transparent;
    }

    .menu-btn:checked ~ .menu-icon .navicon:before {
      transform: rotate(-45deg);
    }

    .menu-btn:checked ~ .menu-icon .navicon:after {
      transform: rotate(45deg);
    }

    .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
    .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
      top: 0;
    }
  }
}

@media (min-width: $mobileBreakPoint) and (max-width: $tabletBreakPoint) {
  #header {
    .booking-page #links {
      clear: both;
      max-height: 0;
      height: 0;
      overflow: hidden;
      transition: max-height 0.2s ease-out;
      position: fixed;
      right: 10px;
      top: 75px;
      background-color: white;
      box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 13px -2px;
      border-radius: 4px;

      li {
        display: flex;
        align-items: center;

        &:hover {
          background: #f0f0f0;
        }
      }

      .tablet-profile-link {
        visibility: visible;
        position: static;
      }

      .dropdown-li {
        position: absolute;
        visibility: hidden;
      }
    }

    #user-menu.booking-page ul#links li {
      float: left;
      clear: both;
      margin-left: 0;
    }

    #user-menu.booking-page .menu-btn:checked ~ ul#links li {
      padding: 5px 10px;
      width: 100%;
      height: 40px;

      a {
        width: 100%;
      }
      span {
        cursor: pointer;
        display: block;
        width: 100%;
        text-align: left;
      }
    }

    #user-menu.booking-page .menu-btn:checked ~ ul#links li ul li {
      padding: 0;
      width: 100%;
      height: 30px;

      &.divider {
        height: 0;
      }
    }

    .booking-page #links {
      clear: none;
      float: right;
      max-height: none;
    }

    .booking-page .menu-icon {
      display: block;
    }
  }
}

@media (min-width: $tabletBreakPoint) {
  //display menu same if checkbox is true in large screen
  #header .booking-page .menu-btn:checked ~ ul#links {
    height: auto;
    width: auto;
  }
}
