.login-popup {
  border-radius: 10px;

  .subscription {
    margin-bottom: 25px;
  }

  h2 {
    text-align: center;
    margin: 0;
    line-height: 30px;
    padding: 18px 0;
  }

  h3 {
    text-align: center;
    margin: 10px auto 20px;
  }

  hr {
    margin: 20px auto 0;
    width: 80%;
  }

  .branded {
    margin-top: 40px;

    .tagline {
      font-weight: bold;
      font-size: 16px;
      padding-top: 20px;
    }

    img {
      max-height: 120px;
    }
  }

  &.leveled:not(.addAccountStep) {
    .close {
      display: none;
      pointer-events: none;
    }
  }

  .back-arrow {
    div {
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 20px;
      margin: 20px;
      cursor: pointer;
    }
  }

  .login-form {
    margin: 25px 10%;

    .sso-container {
      margin: 10px 0;
      text-align: center;
    }

    .sep-container {
      display: flex;
      margin: 10px auto;
      align-items: center;
      max-width: 310px;

      .mid-sep {
        margin: 0 10px;
        color: #9a9a9a;
      }

      .sso-sep {
        flex: 1;
        border-top: 1px solid #c3c3c3;
        height: 1px;
      }
    }

    .sso-button {
      min-width: 310px;
      min-height: 45px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      border: 1px solid #a2a2a2;
      border-radius: 4px;
      font-size: 14px;
      text-transform: lowercase;
      cursor: pointer;
      font-weight: bold;
      font-family: inherit;
    }

    .error-text {
      margin-bottom: 15px;
    }

    .forgot-password-label {
      color: #666666;
      margin: 10px auto;
      display: inline-block;
    }

    .field-wrapper {
      margin-bottom: 20px;

      .error-border {
        border: 1px solid $danger;
      }
    }

    .submit-wrapper {
      text-align: center;

      .btn {
        min-width: 310px;

        &:after {
          content: '';
        }
      }
    }

    .input-wrapper {
      input {
        border: 0;
        padding: 0 45px;
        border-radius: 4px;
      }

      label {
        position: relative;
        display: block;
        border: 1px solid #f0f0f0;
        border-radius: 4px;

        .svg {
          margin: auto;
          position: absolute;
          top: 0;
          left: 10px;
          bottom: 0;
        }
      }
    }
  }

  .external-login-form,
  .forgot-password-form {
    max-width: 310px;
    margin: 20px auto 30px;
    padding: 0 20px;
  }

  .external-login-main {
    .error-msg {
      position: absolute;
    }
  }

  .external-login-desc,
  .forgot-pass-desc {
    padding: 0 20px 10px;
    max-width: 390px;
    margin: 20px auto 0;
    font-size: 18px;
    text-align: center;
  }

  .footer {
    line-height: 60px;
    width: 100%;
    bottom: 0;
  }
}

@media (max-width: 767px) {
  .login-popup {
    .modal-content {
      border-radius: unset;
    }

    h2 {
      line-height: 30px;
      font-size: 24px;
    }

    .login-form {
      margin: 20px 20px 0;

      .forgot-password-label {
        margin: 10px auto 0;
      }
      .submit-wrapper {
        padding: 0 0 10px;
      }
    }

    .footer-text {
      text-align: center;
    }
  }
}

@media (max-width: 320px) {
  .login-popup {
    .modal-content {
      border-radius: unset;
    }

    h3 {
      margin: 10px auto 20px;
    }
    .login-form {
      margin: 20px 10px 0;

      .forgot-password-label {
        margin: 10px auto 0;
      }
      .submit-wrapper {
        padding: 0 0 10px;

        .btn {
          min-width: 200px;
        }
      }
    }

    .footer {
      bottom: 30px;
    }
  }
}
