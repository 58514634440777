.mapbox-complete {
  mark {
    background: inherit;
    color: inherit;
  }

  .mc-suggestion .location-name {
    em,
    mark {
      font-weight: normal;
    }
  }

  .mapbox-main {
    position: relative;
    display: inline-flex;
    direction: ltr;
  }

  .mc-dropdown-menu {
    position: absolute;
    right: auto;
    z-index: 100;
    top: 100%;
    left: 0;
  }

  @media (min-width: 768px) {
    .mc-dropdown-menu {
      min-width: 500px;
    }
  }
}
