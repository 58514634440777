.register-page {
  flex-direction: column;

  > .scope-page-header {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #dadce0;
    padding: 25px 0;

    &.sub-class-last-step {
      display: none;
    }

    .scope-title-container {
      font-size: 23px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 25px;
    }

    .scope-company-name {
      font-weight: bold;
    }

    .scope-steps-container {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
    }

    .scope-step-line {
      width: 12%;
      max-width: 50px;
      border-top: 2px dashed #e5e5e5;
    }

    .scope-step {
      border-radius: 50%;
      background: #e5e5e5;
      height: 1.6em;
      width: 1.6em;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 25px;
    }
  }

  > .scope-content {
    align-items: center;

    > * {
      max-width: 800px;
      width: 100%;
    }

    .extra-footer{
      margin-top: 40px;

      div {
        padding-bottom: 20px;
      }
    }
  }
}

@media (max-width: 767px) {
  .register-page {
    > .scope-page-header {
      .scope-title-container {
        font-size: 16px;
        margin-bottom: 18px;
      }

      .scope-step {
        font-size: 18px;
      }
    }
  }
}
