.site-container._ {

  .banner-header {
    background-color: #EBEBEB;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3em;
    position: sticky;
    z-index: 110;
  }

  .homepage {
    min-height: 65vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .footer {
    margin-top: 0;

    hr {
      height: 0;
    }
  }
}

.loading-booking-overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: $loading_booking_overlay_z_index;
  background: white;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
}

.homepage {
  .brand {
    h1,
    h2 {
      color: white;
      text-align: center;
    }

    h1 {
      font-size: 3.4rem;
      font-weight: 700;
    }

    h2 {
      font-size: 2.4rem;
      padding: 5px 0 20px;
    }
  }
}

@media (max-width: 991px) {
  .carousel-container {
    .search-container {
      width: 100%;
    }
  }
}

@media (max-width: 767px) {
  .site-container._ {
    .banner-header {
      top: 0;
    }
    .homepage {
      min-height: 50vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .site-container {
    background-position: 50% !important;
  }

  .homepage {
    .brand {
      h1,
      h2 {
        color: white;
        text-align: center;
      }

      h1 {
        font-size: 2.1rem;
        margin: 10px 0 0;
      }

      h2 {
        font-size: 1.6rem;
        font-family: inherit;
        padding: 5px 0 20px;
        margin: 0 0 20px;
      }
    }
    .container.search-container {
      margin: 0;
    }
  }
}
