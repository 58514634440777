.password-toggle-scope {
  display: flex;
  position: relative;

  $passwordSvgSize: 25px;
  $passwordSvgPadding: 10px;
  $passwordInputPadding: $passwordSvgSize + ($passwordSvgPadding * 2);

  > input {
    padding-right: $passwordInputPadding;
  }

  > .password-toggle-button {
    align-self: center;
    margin-right: 10px;
    display: inline-flex;
    align-items: center;
    position: absolute;
    justify-content: center;
    cursor: pointer;
    width: $passwordSvgSize;
    height: $passwordSvgSize;
    background: transparent;
    right: 0;
    border: 0;
    padding: 0;

    > div {
      pointer-events: none;
    }

    &:not(.sc-show) .sc-open,
    &.sc-show .sc-closed {
      display: none;
    }

    svg {
      color: #555;
      width: 100%;
      height: 100%;
    }
  }
}
