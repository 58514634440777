$geo-suggest-margin: 5px;

.custom-select {
  position: relative;
  margin: 0;
  border: 1px solid $tempGrey;
  border-radius: 4px;
  background: white;

  &:after {
    content: '\e90b';
    // use 'important' to prevent issues with browser extensions that change fonts
    font-family: 'icomoon' !important;
    font-size: 16px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: $secondary;
  }

  select {
    padding: 5px 40px 5px 10px;
    -webkit-appearance: none;
    -ms-appearance: none;
    -moz-appearance: none;
    background-color: transparent;
    border-radius: 0;
    border: 0;
    position: relative;
    cursor: pointer;

    option {
      color: initial;
    }

    &:focus {
      outline: none;
    }
  }

  &.disabled {
    &:after {
      display: none;
    }

    select {
      text-shadow: 0 0 0 $primary;
    }
  }
}

// Radio buttons
input[type='radio'] {
  display: none;

  & + label {
    cursor: pointer;

    .radio-btn {
      display: inline-block;
      width: 15px;
      height: 15px;
      margin-top: -1px;
      vertical-align: middle;
      cursor: pointer;
      border: 2px solid $light;
      border-radius: 50%;
      position: relative;

      &:after {
        display: none;
        content: ' ';
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: $light;
        position: absolute;
        top: 2px;
        left: 2px;
      }
    }

    &:hover {
      .radio-btn {
        &:after {
          display: block;
        }
      }
    }
  }

  &:checked {
    & + label {
      .radio-btn {
        border-color: $accent;

        &:after {
          display: block;
          background-color: $accent;
        }
      }
    }
  }
}

// Checkboxes
input[type='checkbox'] {
  display: none;

  & + label {
    cursor: pointer;

    .chk-btn {
      display: inline-block;
      width: 17px;
      height: 17px;
      margin: -1px 0 0 0;
      cursor: pointer;
      vertical-align: middle;
      border: 1px solid $light;
      position: relative;
      border-radius: 2px;

      &:after {
        display: none;
        // use 'important' to prevent issues with browser extensions that change fonts
        font-family: 'icomoon' !important;
        content: '\e904';
        color: $light;
        position: absolute;
        font-size: 11px;
        top: 0;
        left: 2px;
      }
    }

    .chk-lbl {
      display: inline-block;
      margin-left: 5px;

      &.ml-5 {
        width: calc(100% - 20px);
      }

      &.ml-15 {
        width: calc(100% - 30px);
      }
    }

    &:hover {
      .chk-btn {
        &:after {
          display: block;
        }
      }
    }
  }

  &:checked {
    & + label {
      .chk-btn {
        &:after {
          color: $dark;
          display: block;
        }
      }
    }
  }
}

.checkbox-tabs {
  height: 38px;
  width: 80%;
  margin: 10px auto;

  .tab {
    float: left;
    width: 50%;

    &:first-child {
      label {
        border-radius: 4px 0 0 4px;
      }
    }

    &:nth-child(2) {
      label {
        border-radius: 0 4px 4px 0;
      }
    }

    input[type='checkbox'],
    input[type='radio'] {
      display: none;
    }

    label {
      height: 38px;
      line-height: 38px;
      color: $secondary;
      text-align: center;
      display: block;
      border: 1px solid $tempGrey;
    }

    input:checked ~ label {
      background: #f7f7f7;
      font-weight: bold;
    }
  }
}

.form-content {
  input,
  textarea {
    font-family: inherit;
    color: $secondary;
    height: 42px;
    padding: 10px 20px;
    font-size: 16px;
    border: 1px solid #d3d0d0;
    width: 100%;
    border-radius: 0;
    -webkit-appearance: none;

    &:focus {
      outline: none;
    }

    &.sm {
      height: 36px;
    }
  }

  textarea {
    height: 150px;
  }
}

.error-msg {
  line-height: 16px;
  padding: 5px 0;
  min-height: 20px;
  font-size: 12px;
  color: $danger;
}

.field-wrapper {
  display: inline-block;
  width: 100%;

  .input-wrapper {
    position: relative;

    &-sm {
      input {
        height: 56px;
      }

      .valid-icon {
        top: 12px;
      }
    }

    input {
      padding-right: 35px;
    }

    .valid-icon {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 16px;
      // color: $accent;
    }
  }

  .vam-label {
    height: 42px;
    display: inline-block;
    vertical-align: middle;
    width: 0;
  }

  .label-wrapper {
    text-align: right;
    line-height: 1.1;

    label {
      font-size: 16px;
      color: #666666;
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.upload-file-wrapper {
  display: inline-block;
  margin: 0 10%;
  text-align: center;

  .upload-file {
    position: relative;
    height: 65px;
    width: 65px;
    margin: 0 auto 5px;
    cursor: pointer;

    span {
      background: $secondary;
      color: $accent;
      font-size: 35px;
      padding: 15px;
      border-radius: 50%;
      display: inline-block;
    }

    .upload-input {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }
}

/* Register form */
.mandatory-fields-text {
  font-size: 16px;
  color: #666666;
  margin-bottom: 35px;
}

.already-registered {
  color: $secondary;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  margin-top: 20px;

  .link {
    color: $secondary;
    font-size: 14px;
    text-decoration: underline;
    margin-left: 30px;
    cursor: pointer;
  }
}

.form-tabs {
  background: #eaeaea;
  min-height: 33px;
  display: inline-flex;
  width: 100%;
  overflow: hidden;
  align-items: center;

  .tab {
    text-align: center;
    float: left;
    width: 50%;
    position: relative;

    &.active {
      background: $accent2;

      &:first-child {
        &:before {
          background-color: $accent2;
        }
      }
    }

    &:first-child {
      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        width: 45px;
        height: 45px;
        border-right: 8px solid #ffffff;
        border-top: 8px solid #ffffff;
        transform: rotate(45deg);
        top: -5px;
        right: -8px;
        z-index: 1;
        background-color: #eaeaea;
      }

      span {
        float: right;
        z-index: 1;
      }
    }

    span {
      display: block;
      padding: 8px 20px;
      font-family: inherit;
      font-size: 14px;
      text-transform: uppercase;
      color: $secondary;
      position: relative;
      max-width: 470px;
      width: 100%;
      float: left;
    }
  }
}

.form-section {
  padding: 20px 0;
  display: inline-block;
  width: 100%;

  a {
    color: #000000;
    text-decoration: underline;
  }

  .form-section-title {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .form-section-content {
    padding: 35px 0;
  }
}

.action-buttons {
  margin-top: 50px;
}

.souscription-form-info {
  font-size: 0;
  text-align: center;
  padding-right: 60px;
  display: inline-block;

  .description {
    display: inline-block;
    font-size: 14px;
    width: calc(100% - 60px);
    vertical-align: middle;
  }

  .title {
    margin-top: 30px;
    font-size: 16px;
    padding-left: 60px;
  }

  .icon-title {
    width: 60px;
    text-align: center;
    font-size: 24px;
    display: inline-block;
    vertical-align: middle;
  }
}

.payment-card {
  font-size: 14px;
  text-align: center;

  .title {
    margin-top: 30px;
    font-size: 20px;
  }
}

.modify-account {
  .title {
    font-size: 20px;
  }

  .subtitle {
    font-size: 16px;
  }

  .action-buttons {
    margin: 0;
    text-align: right;
  }
}

.custom-checkbox + label {
  font-size: 12px;
}

// Yellow background on input fix for webkit
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

@media (max-width: 767px) {
  .form-section {
    padding: 10px 0;

    .form-section-content {
      padding: 20px 0;
    }
  }

  .action-buttons {
    margin-top: 30px;
  }

  .field-wrapper {
    .label-wrapper {
      text-align: left;
    }

    select {
      margin-top: 20px;

      &.no-margin {
        margin: 0;
      }
    }
  }

  .souscription-form-info {
    display: block;
    text-align: left;
    padding-right: 0;

    .description {
      display: inline-block;
      font-size: 14px;
      width: calc(100% - 60px);
    }

    .title {
      margin-top: 30px;
      font-size: 16px;
      padding-left: 0;
    }

    .icon-title {
      width: 40px;
      text-align: center;
      font-size: 20px;
    }
  }

  .modify-account {
    text-align: center;

    .action-buttons {
      text-align: center;
      margin: 20px 0;
    }
  }
}
