.has-input-pin {
  position: relative;

  .mc-input {
    &::-ms-clear {
      display: none;
    }
  }

  .input-pin {
    position: absolute;
    width: 40px;
    left: 0;
    top: 0;
    text-align: center;
    line-height: 36px;
    color: $secondary;
    font-size: 16px;
    z-index: 5;
    cursor: pointer;
  }
}

.input-wrapper-sm {
  .mc-input {
    height: 36px;
  }
}
