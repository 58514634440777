@import 'intl-tel-input/build/css/intlTelInput.css';

.iti__globe {
  background-image: url('/assets/libs/phone-number/globe.png');
}
.iti__flag {
  background-image: url('/assets/libs/phone-number/flags.png');
}

@media (min-resolution: 2x) {
  .iti__globe {
    background-image: url('/assets/libs/phone-number/globe@2x.png');
  }
  .iti__flag {
    background-image: url('/assets/libs/phone-number/flags@2x.png');
  }
}

.iti--allow-dropdown input.iti__tel-input,
.iti--allow-dropdown input.iti__tel-input[type='text'],
.iti--allow-dropdown input.iti__tel-input[type='tel'],
.iti--show-selected-dial-code input.iti__tel-input,
.iti--show-selected-dial-code input.iti__tel-input[type='text'],
.iti--show-selected-dial-code input.iti__tel-input[type='tel'] {
  padding-right: 6px;
  padding-left: 60px;
}
[dir='rtl'] .iti--allow-dropdown input.iti__tel-input,
[dir='rtl'] .iti--allow-dropdown input.iti__tel-input[type='text'],
[dir='rtl'] .iti--allow-dropdown input.iti__tel-input[type='tel'],
[dir='rtl'] .iti--show-selected-dial-code input.iti__tel-input,
[dir='rtl'] .iti--show-selected-dial-code input.iti__tel-input[type='text'],
[dir='rtl'] .iti--show-selected-dial-code input.iti__tel-input[type='tel'] {
  padding-right: 60px;
  padding-left: 6px;
}
