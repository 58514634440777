$bookBoxMobileButtonHeight: 70px;
$recapTitleFontSizeNormal: 26px;
$recapTitleFontSizeMobile: 20px;
$recapExtraMarginBeforeSideBlock: 10px;

.modal .recap-popup.modal-dialog {
  transform: none;
  pointer-events: none;

  .car-popup {
    pointer-events: none;
    display: inline;
  }
}

.recap-popup {
  .close {
    pointer-events: all;
  }

  .custom-fields-form {
    .scope-section-title {
      display: none;
    }
  }

  @mixin subTitleFont() {
    font-size: 16px;
    font-weight: bold;
  }

  .btn.sub-class-confirm-button.sub-class-disabled {
    @include disabledButtonColors;
    cursor: wait;
  }

  .profile-page {
    display: block;
    min-height: auto;
    max-width: none;
    box-shadow: none;
    margin: 0;
  }

  .scope {
    &-side-modal-price-before-voucher {
      margin-left: 10px;
      font-size: 16px;
      font-weight: bold;
      color: #989898;
      text-decoration: line-through;
    }

    &-inner-row-padding {
      padding: 0 15px;
    }

    &-before-voucher-price-field {
      text-decoration: line-through;
      font-size: 0.9em;
      margin-right: 0.5em;
      color: #848484;
    }

    &-accept-terms-container {
      margin-top: 30px;

      a {
        color: #0091ff;
        text-decoration: underline;
        cursor: pointer;
      }

      .scope {
        &-boolean-info {
          margin-right: 10px;
        }

        &-boolean-container {
          display: flex;
          align-items: center;

          .switch {
            flex-shrink: 0;
          }
        }
      }
    }

    &-date-range {
      display: flex;
      align-items: center;
      font-size: 15px;

      svg {
        height: 1em;
        margin: 0 10px;
        transform: rotate(180deg);
      }
    }

    &-voucher-container {
      display: flex;
      font-size: 14px;

      input {
        font-size: 1em;
        border: 1px solid #f0f0f0;
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
        border-right-width: 0;
        flex-grow: 1;
        padding: 8px 14px;
        outline: none;
      }

      button {
        cursor: pointer;
        outline: none;
        font-size: 1em;
        background: #2c2c2c;
        color: white;
        border: 1px solid black;
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
        padding: 0 16px;
      }
    }

    &-confirm-recap {
      &-price-field {
        display: flex;
        justify-content: space-between;
        font-size: 15px;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }

        .scope-field-name {
          color: #848484;
        }

        > .scope-field-value {
          font-weight: bold;
        }
      }

      &-title {
        font-size: $recapTitleFontSizeNormal;
        font-weight: bold;
      }

      &-sub-title {
        @include subTitleFont;
        margin-bottom: 20px;
      }

      &-car-name {
        @include subTitleFont;
        margin-bottom: 10px;
      }

      &-car-details {
        display: flex;
        align-items: center;
      }

      &-picture {
        height: 100px;
        width: 100px;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 20px;
      }

      &-car-time-name {
        display: inline-block;
      }

      &-title-container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-bottom: 20px;
      }

      &-button-return {
        display: inline-flex;
        padding: 5px;
        cursor: pointer;
        left: 0;
        position: absolute;

        svg {
          width: 22px;
        }
      }
    }
  }

  .recap-modal-body {
    pointer-events: all;
    background-color: #fff;
    border-radius: 4px;
  }

  .modal-content {
    .close {
      display: none;
    }
  }
}

.car-popup {
  .book-box {
    pointer-events: all;
    background-color: #fff;
    border-radius: 4px;
    padding: 10px;
    margin: 0;
    width: 230px;
    height: 150px;
    position: fixed;

    .price {
      font-size: 40px;
      font-weight: 700;
      white-space: nowrap;
    }

    .datetime-range {
      margin-bottom: 15px;
      margin-top: 5px;
    }

    .btn {
      width: 100%;
    }
  }

  .accordion-header {
    margin-top: 0;
  }

  .accordion-button {
    display: flex;
    box-shadow: none;
    justify-content: space-between;
    color: #848484;
    padding: 0;
    font-size: 14px;
    pointer-events: none;
    background: inherit;

    button {
      cursor: pointer;
      pointer-events: all;
      background: transparent;
      border: 0;
    }

    &:focus {
      box-shadow: revert;
    }

    &::after {
      display: none;
    }

    .react-svg.info {
      fill: black;
      width: 16px;
      display: inline-flex;
      align-items: center;
      font-size: 18px;
    }
  }

  .panel-body {
    display: block;
    white-space: pre-line;
    margin-top: 10px;
    color: #848484;
  }

  .wrapper {
    .car-popup-label {
      display: none;
      font-weight: bold;
      text-align: center;
    }

    .wrapper-image {
      padding: 0;
    }

    .car-image {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 -10px 10px;

      img {
        object-fit: cover;
        border: 10px solid #fff;
        border-radius: 15px;
      }
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .car-info {
        line-height: 30px;
      }

      .parking-info {
        line-height: 24px;
      }

      .brand-model {
        font-weight: bold;
        font-size: 28px;
      }

      .plate-number {
        color: #9b9b9b;
        font-size: 20px;
      }

      .gaz {
        display: flex;
        align-items: center;

        .react-svg {
          margin-right: 5px;
        }
      }

      .parking-name {
        font-weight: bold;
        font-size: 18px;
        max-width: 210px;
      }
    }

    .row {
      margin: 0;

      .col-md-6,
      .col-md-12 {
        padding: 0;
      }

      .title,
      .parking-additional-info {
        font-weight: bold;
        font-size: 18px;
        line-height: 30px;
      }
    }

    .content {
      padding: 0;
      line-height: 30px;

      .label {
        font-size: 16px;
        color: #848484;

        &.special {
          &::after {
            content: '';
            background-color: lightgray;
            border-radius: 10px;
            color: white;
            position: absolute;
            padding: 5px;
            margin: 10px;
          }
        }
      }

      .spacing {
        margin-left: 30px;
      }

      .value {
        font-weight: bold;
        font-size: 16px;
        color: #000;
      }
    }

    ul {
      list-style-type: none;
    }

    li {
      position: relative;
      display: block;
      height: 50px;
      padding-bottom: 0;
      margin-bottom: 10px;
      padding-left: 20px;

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        top: 21px;
        left: 5px;
        height: 60px;
        width: 3px;
      }

      &:last-child {
        &::before {
          top: 0;
          height: 0;
        }
      }

      &::after {
        content: '';
        position: absolute;
        border-radius: 50%;
        left: 2px;
        top: 15px;
        height: 9px;
        width: 9px;
      }
    }
  }

  .price-before-voucher {
    text-decoration: line-through;
    color: #bdbdbd;
    font-size: 20px;
    margin-left: 20px;

    span {
      vertical-align: top;
    }
  }

  .car-detail-pic {
    max-height: 360px;
    object-fit: contain;
  }

  .car-detail-confirm {
    text-align: right;
    padding-bottom: 10px;
  }

  &.recap {
    .content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.text {
        text-align: justify;
      }
    }
  }
}

@media (min-width: 993px) {
  .modal .recap-popup.modal-dialog {
    width: 100%;
    max-width: 940px;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .recap-popup {
    .modal-content {
      display: contents;
    }

    .wrapper {
      padding: 15px 30px 20px;
    }

    .recap-modal-body {
      margin-right: 10px;
    }

    .scope-confirm-recap {
      padding: 30px 20px 40px;
    }

    .book-box-col {
      width: calc(33.33333333% - #{$recapExtraMarginBeforeSideBlock});
    }

    .book-box {
      height: auto;
      width: 280px;
      padding: 15px;

      .datetime-range:first-child {
        margin-top: 10px;
        margin-bottom: 20px;
      }
    }

    .btn.sub-class-confirm-button {
      font-size: 15px;
      min-height: 45px;
      height: auto;
      line-height: normal;
    }
  }
}

@media (max-width: 992px) {
  .modal .recap-popup.modal-dialog {
    margin-top: 0;
    width: 100%;
    max-width: 100%;
  }

  .modal-parent.sub-recap-popup {
    .outer-close {
      display: none;
    }
  }

  .recap-popup {
    margin: 0;
    width: 100%;

    .modal-content {
      border-radius: 0;
    }

    .scope-confirm-recap {
      padding-top: 20px;
      padding-bottom: 20px;
      min-height: 100vh;
      border-radius: 0;

      > .row {
        background: white;
      }
    }

    .wrapper {
      border-radius: 0;
      min-height: 100vh;
    }

    .book-box-col {
      position: absolute;
    }

    .recap-modal-body {
      width: 100%;
    }

    .book-box {
      background-color: #fff;
      border-radius: unset;
      padding: 0 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.24);
      width: 100%;
      height: $bookBoxMobileButtonHeight;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      top: auto;

      .price,
      .datetime-range {
        display: none;
      }

      .btn {
        width: 100%;
        font-size: 15px;
      }
    }

    .modal-content .close {
      display: inline-block;
      top: 25px;
      right: 15px;
      color: $dark;
    }
  }

  .car-popup {
    .car-detail-confirm {
      text-align: center;
      padding-bottom: 20px;
    }

    &.vehicle {
      margin-top: 50px;

      .content {
        .information {
          padding: 45px 75px 0;
        }
      }
    }

    .wrapper-image {
      .car-popup-label {
        margin-bottom: 10px;
        display: block;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .recap-popup {
    $buttonHeightTablet: 75px;

    .wrapper-image {
      .car-popup-label {
        font-size: $recapTitleFontSizeNormal;
      }
    }

    .scope-confirm-recap {
      padding-bottom: (20px + $buttonHeightTablet);
    }

    .wrapper {
      padding: 20px 20px ($buttonHeightTablet + 20px);
    }

    .book-box {
      padding: 0 15px;
      height: $buttonHeightTablet;
    }
  }
}

@media (max-width: 767px) {
  .recap-popup .modal-content .close {
    top: 22px;
  }

  .car-popup {
    &.recap {
      .book-box {
        padding: 0 15px;
      }

      .wrapper {
        padding: 20px 20px 90px;
      }

      .wrapper-image {
        .car-popup-label {
          font-size: $recapTitleFontSizeMobile;
        }
      }

      .profile-page {
        padding-bottom: 0;
      }

      .scope-confirm-recap {
        padding-bottom: (20px + $bookBoxMobileButtonHeight);

        &-title {
          font-size: $recapTitleFontSizeMobile;
        }
      }

      .scope-voucher-container {
        font-size: 16px;
      }

      .header {
        .car-info {
          line-height: 20px;
        }

        .parking-info {
          line-height: 16px;
          font-size: 12px;
        }

        .brand-model {
          font-size: 16px;
        }

        .plate-number {
          font-size: 12px;
        }

        .gaz {
          .react-svg {
            width: 16px;
          }
        }

        .parking-name {
          font-size: 13px;
        }
      }

      .col-md-4 {
        padding: 0;
      }

      .content {
        .label {
          font-size: 14px;
        }

        .value {
          font-size: 13px;
        }
      }
    }
  }
}
