$fileUploadNameMargin: 8px;

$newFieldHeightPadding: 13px;
$newFieldWidthPadding: 15px;

$newFieldMobileHeightPadding: 12px;
$newFieldMobileWidthPadding: 10px;

.new-design-file-upload {
  .scope {
    &-status-icon-container {
      display: flex;
      align-items: center;
      margin-left: $fileUploadNameMargin;

      .spinner {
        font-size: 1.1em;
      }
    }

    &-file-uploaded-icon {
      height: 0.8em;
    }

    &-file-error-icon {
      height: 0.9em;
      fill: #f44336;
    }

    &-file-attachment {
      &-icon {
        height: 1em;
        margin-right: $fileUploadNameMargin;
      }

      &-name {
        word-break: break-all;
      }

      &-container {
        font-size: 15px;
        display: flex;
        align-items: center;
        margin-top: auto;
        padding-top: 10px;
        z-index: 2;
      }
    }
  }

  .new-design-dropzone {
    outline: none;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    min-height: 200px;
    background: #fbfbfb;
    border-radius: 4px;
    border: 2px dashed #d7d7d7;
    position: relative;

    .img-container {
      flex-grow: 1;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 5em;

      .spinner {
        border-width: 0.1em;
      }

      img {
        object-fit: contain;
        max-height: 300px;
        max-width: 300px;
        width: 100%;
      }
    }

    &-update,
    &-create {
      outline: none;
      cursor: pointer;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 10px;
      height: 100%;
      background: #fbfbfb;
      border-radius: 4px;
      position: absolute;
      border: 0;
      transition: all 0.3s ease;
    }

    &-update {
      opacity: 0;
      z-index: 1;

      &:not(.sub-loading):hover {
        opacity: 1;
      }
    }

    &-create {
      opacity: 1;
    }

    &.sub-class {
      &-drag-active {
        background: #fcfcfc;
        border-color: #a4a4a4;
      }

      &-uploading {
        &.sub-class-drag-active {
          border-color: #ff9090;
        }

        cursor: not-allowed;
      }

      &-disabled {
        cursor: default;
        padding: 0;
        border: 0;
      }
    }

    &-input {
      display: none;
    }

    &-svg-container {
      svg {
        fill: #898e96;
        height: 40px;
      }

      margin-bottom: 20px;
    }

    &-title {
      font-size: 16px;
      margin-bottom: 5px;
    }

    &-info {
      font-size: 14px;
      color: #848484;
    }
  }
}

.new-design-date-picker:not(:disabled) {
  caret-color: transparent;
  cursor: pointer;
}

.new-design-radio-group {
  .new-design-radio-container {
    display: inline-block;
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }

    .new-design-radio {
      &-label {
        font-size: 16px;
        display: inline-flex;
        align-items: center;

        &:hover {
          .new-design-radio-btn {
            &:after {
              display: inline-flex;
            }
          }
        }
      }

      &-btn {
        display: inline-block;
        width: 15px;
        height: 15px;
        cursor: pointer;
        border: 2px solid $light;
        border-radius: 50%;
        position: relative;
        margin-right: 8px;

        &:after {
          display: none;
          content: ' ';
          width: 7px;
          height: 7px;
          border-radius: 50%;
          background-color: $light;
          position: absolute;
          top: 2px;
          left: 2px;
        }
      }

      &-input:checked {
        & + .new-design-radio-label {
          .new-design-radio-btn {
            &:after {
              display: block;
            }
          }
        }
      }
    }
  }
}

.new-design-field,
.new-design-date-picker,
.profile-page .mapbox-complete .mc-input {
  border-style: solid;
  border-color: #f0f0f0;
  border-width: 2px;
  border-radius: 4px;
  padding: $newFieldHeightPadding $newFieldWidthPadding;
  font-size: 16px;
  width: 100%;
  outline: none;
  appearance: none;
  -webkit-appearance: none;

  &.sub-class-disabled,
  &:disabled,
  &[readonly] {
    background: none;
    border: 0;
    padding: 0;
    color: inherit;
    font-weight: bold;
    font-size: 15px;
    width: auto;
    cursor: text;
  }
}

.profile-page .location-input .mapbox-complete {
  font-size: 16px;

  .mc-input {
    line-height: normal;
    height: auto;
  }
}

.new-design-select-container {
  $fieldFontSize: 16px;

  width: 100%;
  display: inline-flex;
  align-items: center;
  border: 2px solid #f0f0f0;
  border-radius: 4px;
  position: relative;
  font-size: $fieldFontSize;
  flex-shrink: 0;

  .new-design-select {
    appearance: none;
    -webkit-appearance: none;

    &-styled {
      display: flex;
      align-items: center;
      font-size: $fieldFontSize;
      justify-content: space-between;
      height: 100%;
      width: 100%;
      position: absolute;
      background: white;
      pointer-events: none;
      border-radius: 4px;
      padding: $newFieldHeightPadding $newFieldWidthPadding;

      svg {
        height: 8px;
      }
    }

    &-value {
      white-space: nowrap;
      margin-right: 8px;
    }

    &-field {
      font-size: $fieldFontSize;
      cursor: pointer;
      height: 100%;
      border: 0;
      width: 100%;
      border-radius: 4px;
      outline: none;
      padding: $newFieldHeightPadding $newFieldWidthPadding;
      appearance: none;
      -webkit-appearance: none;
    }

    select.new-design-select-field {
      appearance: none;
      -webkit-appearance: none;
    }
  }
}

.field-error-info {
  &.border {
    margin-top: 20px;
    border-bottom: 2px solid rgba(255, 0, 0, 0.7);
    width: 100%;
  }

  &.text {
    font-size: 13px;
    margin-top: 10px;
    margin-left: 5px;
    color: #dd413c;
  }
}

@media (max-width: 767px) {
  .new-design-field,
  .new-design-date-picker,
  .profile-page .mapbox-complete .mc-input {
    font-size: 16px;
    border-width: 1px;
    padding: $newFieldMobileHeightPadding $newFieldMobileWidthPadding;

    &.sub-class-disabled,
    &:disabled {
      font-size: 16px;

      &:first-of-type {
        padding-left: 8px;
      }
    }
  }

  .profile-page .location-input .mapbox-complete {
    font-size: 13px;
  }

  .new-design-file-upload {
    .scope {
      &-file-attachment {
        &-container {
          font-size: 14px;
          margin-top: 6px;
        }
      }
    }

    .new-design-dropzone {
      &-title {
        font-size: 14px;
      }

      &-info {
        font-size: 13px;
      }
    }
  }

  .new-design-select-container {
    $fieldFontSize: 16px;

    font-size: $fieldFontSize;
    border-width: 1px;

    .new-design-select {
      &-field {
        font-size: $fieldFontSize;
        padding: $newFieldMobileHeightPadding $newFieldMobileWidthPadding;
      }

      &-styled {
        padding: $newFieldMobileHeightPadding $newFieldMobileWidthPadding;

        svg {
          height: 6px;
        }
      }
    }
  }

  .new-design-radio-group {
    display: flex;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    padding: 2px;

    .new-design-radio-container {
      flex-grow: 1;
      margin-right: 0;
      padding-right: 2px;

      &:last-child {
        padding-right: 0;
      }

      .new-design-radio {
        &-btn {
          display: none;
        }

        &-label {
          display: flex;
          justify-content: center;
          font-size: 13px;
          padding: 8px;
          border-radius: 4px;
          background: #f0f0f0;

          &:hover {
            background: #e8e8e8;
          }
        }
      }
    }
  }

  .field-error-info {
    &.border {
      margin-top: 15px;
    }

    &.text {
      margin-top: 5px;
      margin-left: 4px;
      font-size: 12px;
    }
  }
}
