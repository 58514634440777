.payment-info-page {
  form {
    position: relative;
    .loader {
      position: absolute;
      top: 30%;
      left: 50%;
      transform: translate(-50%, -30%);
      z-index: 1;

      svg.svg-loader {
        height: 50px;
      }
    }
  }

  .scope {
    &-title-text {
      font-size: 1.5em;
    }

    &-content-body {
      &.load {
        opacity: 50%;
        pointer-events: none;
      }
      .scope-field-container {
        margin-bottom: 20px;
      }
    }

    &-credit-card {
      &-container {
        display: flex;
        align-items: center;
        padding: 5px 10px;
        border-radius: 4px;

        .close-icon {
          width: 20px;
          height: 20px;
          fill: #aeaeae;
          margin-left: auto;
          margin-right: 10px;
          cursor: pointer;
          transition: fill 0.2s ease;

          &:hover {
            fill: #000;
          }
        }
      }

      &-inner {
        margin-left: 35px;

        > * {
          &:first-child {
            margin-bottom: 5px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &-number {
        font-size: 18px;
        font-weight: bold;
      }

      &-date {
        font-size: 14px;
        color: #848484;
      }
    }
  }
}

@media (max-width: 767px) {
  .payment-info-page {
    .scope {
      &-title-text {
        font-size: 1.1em;
      }

      &-content-body {
        &.load {
          opacity: 50%;
          pointer-events: none;
        }
        .scope-field-container:first-child {
          margin-bottom: 20px;
        }
      }
      &-content-footer {
        &.small-screen {
          z-index: 11;
        }
      }
      &-credit-card {
        &-container {
          padding: 0;
          border-width: 1px;
        }

        &-number {
          font-size: 16px;
        }

        &-inner {
          margin-left: 10px;
        }

        &-date {
          font-size: 12px;
        }
      }
    }
  }
}
