.site-container[class*='_creation'],
.site-container[class*='_register'] {
  .footer {
    margin-top: 0;

    hr {
      max-width: none;
    }
  }
}

.subscription {
  .wrapper {
    position: relative;
    top: -66px;
    background-color: white;
    z-index: 2;

    h2 {
      text-align: center;
      margin: 0;
      line-height: 1;
      padding: 18px 0 40px;

      button {
        background: transparent;
        border: 0;
        font-size: 25px;
        line-height: 1;
        position: absolute;
        right: 19px;
        top: 19px;
        cursor: pointer;
      }
    }
    .text-center {
      width: 80%;
      margin: 0 auto;
    }

    .svg-loader {
      margin: 20px 0;
      height: 40px;
    }
  }

  .ws {
    white-space: pre-wrap;
  }

  .imgs img {
    max-height: 50px;
    margin-top: 20px;

    a {
      &:first-child {
        margin-right: 5px;
      }

      &:last-child {
        margin-left: 5px;
      }
    }
  }

  .padd-text {
    padding: 0 100px;
  }

  .btn {
    margin-top: 20px;

    &:after {
      content: '';
    }
  }

  .offers {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 80%;
    margin: 0 auto;

    div {
      height: 195px;
      width: 174px;
      background-repeat: no-repeat;
      background-position: center 10px;
      border: 1px solid #f0f0f0;
      border-radius: 5px;
      cursor: pointer;
      margin: 0 5px 5px;
      align-items: flex-end;
      display: flex;
      padding: 0 0 10px;
      transition: all ease 0.3s;
      justify-content: center;

      &:hover {
        box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.24);
      }
    }

    .corporate {
      //noinspection CssUnknownTarget
      background-image: url('/assets/images/usage-types/corporate.svg');
    }

    .private {
      //noinspection CssUnknownTarget
      background-image: url('/assets/images/usage-types/private.svg');
    }

    .vr {
      //noinspection CssUnknownTarget
      background-image: url('/assets/images/tools-circle.svg');
    }
  }
}

@media (max-width: 320px) {
  .subscription .offers {
    width: 100%;
    padding-bottom: 100px;

    div {
      background-size: 70%;
      height: 160px;
      width: 140px;

      span {
        padding: 0 30px;
      }
    }
  }
}

@media (max-width: 767px) {
  .site-container[class*='_creation'],
  .site-container[class*='_register'] {
    .footer {
      display: none;
    }
  }

  .subscription .offers {
    width: 100%;
    padding-bottom: 100px;
  }

  .submit-wrapper {
    padding: 20px 0 30px;
  }
}
