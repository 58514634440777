* {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}

a {
  display: inline-block;
  color: #000000;
}

strong {
  font-weight: 700;
}

.capitalize {
  text-transform: capitalize;
}

.italic {
  font-style: italic;
}

.f {
  &-0 {
    font-size: 0;
  }

  &-12 {
    font-size: 12px;
  }

  &-13 {
    font-size: 13px;
  }

  &-14 {
    font-size: 14px;
  }

  &-15 {
    font-size: 15px;
  }

  &-16 {
    font-size: 16px;
  }

  &-18 {
    font-size: 18px;
  }

  &-20 {
    font-size: 20px;
  }

  &-22 {
    font-size: 22px;
  }

  &-24 {
    font-size: 24px;
  }

  &-25 {
    font-size: 25px;
  }

  &-26 {
    font-size: 26px;
  }

  &-28 {
    font-size: 28px;
  }

  &-30 {
    font-size: 30px;
  }

  &-32 {
    font-size: 32px;
  }

  &-34 {
    font-size: 34px;
  }

  &-35 {
    font-size: 35px;
  }

  &-40 {
    font-size: 40px;
  }
}

.pointer {
  cursor: pointer !important;
}

.ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nowrap {
  white-space: nowrap;
}

.ws-pre-line {
  white-space: pre-line;
}

.break-word {
  word-break: break-word;
}

.uppercase {
  text-transform: uppercase;
}

.underline {
  text-decoration: underline !important;
}

.decoration-none {
  text-decoration: none !important;
}

.bold {
  font-weight: 700 !important;
}

// Aligns

.text {
  &-center {
    text-align: center !important;
  }

  &-left {
    text-align: left !important;
  }

  &-right {
    text-align: right !important;
  }

  &-justify {
    text-align: justify !important;
  }
}

@media (max-width: 767px) {
  .text {
    &-center-xs {
      text-align: center !important;
    }

    &-left-xs {
      text-align: left !important;
    }

    &-right-xs {
      text-align: right !important;
    }
  }

  .f {
    &-xs-0 {
      font-size: 0;
    }

    &-xs-12 {
      font-size: 12px;
    }

    &-xs-13 {
      font-size: 13px;
    }

    &-xs-14 {
      font-size: 14px;
    }

    &-xs-15 {
      font-size: 15px;
    }

    &-xs-16 {
      font-size: 16px;
    }

    &-xs-18 {
      font-size: 18px;
    }

    &-xs-20 {
      font-size: 20px;
    }

    &-xs-22 {
      font-size: 22px;
    }

    &-xs-24 {
      font-size: 24px;
    }

    &-xs-25 {
      font-size: 25px;
    }

    &-xs-26 {
      font-size: 26px;
    }

    &-xs-28 {
      font-size: 28px;
    }

    &-xs-30 {
      font-size: 30px;
    }

    &-xs-32 {
      font-size: 32px;
    }

    &-xs-34 {
      font-size: 34px;
    }

    &-xs-35 {
      font-size: 35px;
    }

    &-xs-40 {
      font-size: 40px;
    }
  }
}

@media (max-width: 991px) {
  .text {
    &-center-sm {
      text-align: center !important;
    }

    &-left-sm {
      text-align: left !important;
    }

    &-right-sm {
      text-align: right !important;
    }
  }
}

.va {
  &-middle {
    vertical-align: middle !important;
  }

  &-top {
    vertical-align: top !important;
  }

  &-bottom {
    vertical-align: bottom !important;
  }
}

.vam-wrapper {
  display: table;
  height: 100%;
  width: 100%;
}

.vam {
  display: table-cell;
  vertical-align: middle;
}

// Displays
.dis-ib {
  display: inline-block !important;
}

.dis-b {
  display: block !important;
}

.dis-t {
  display: table !important;
}

.dis-tc {
  display: table-cell !important;
}

.show {
  display: block !important;
}

.hide {
  display: none !important;
}

// Positions
.pos-rel {
  position: relative !important;
}

.pos-abs {
  position: absolute !important;
}

.pos-fix {
  position: fixed !important;
}

// Width
.w-25 {
  width: 25% !important;
}

.w-33 {
  width: 33.3333% !important;
}

.w-50 {
  width: 50% !important;
}

.w-66 {
  width: 66.6666% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

// Height
.h-25 {
  height: 25% !important;
}

.h-33 {
  height: 33.3333% !important;
}

.h-50 {
  height: 50% !important;
}

.h-66 {
  height: 66.6666% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

// Boreders
.bt {
  border-top: 1px solid $light;
}

.bl {
  border-left: 1px solid $light;
}

.bb {
  border-bottom: 1px solid $light;
}

.br {
  border-right: 1px solid $light;
}

// Margins
@include margin(0);
@include margin(5);
@include margin(10);
@include margin(15);
@include margin(20);
@include margin(25);
@include margin(30);
@include margin(35);
@include margin(40);
@include margin(45);
@include margin(50);
@include margin(55);

// Paddings
@include padding(0);
@include padding(5);
@include padding(10);
@include padding(15);
@include padding(20);
@include padding(25);
@include padding(30);
@include padding(35);
@include padding(40);
@include padding(45);
@include padding(50);
@include padding(55);
