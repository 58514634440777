.site-container {
  &._select-company,
  .select-brand-company {
    min-height: 78vh;
  }

  .footer {
    margin-top: 0;

    hr {
      max-width: none;
    }
  }
}

.select-brand-company {
  display: flex;
  overflow: hidden;
  flex-direction: column;

  .sc-entry-icon {
    display: flex;
    align-items: center;
    gap: 20px;
    font-weight: bold;
    font-size: 1.2em;
  }

  .sc-title-msg {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 1.2em;
  }

  .sc-item {
    display: inline-flex;
    background: transparent;
    flex-direction: column;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 0;
    margin: 0;
    padding: 0;

    &:hover {
      .sc-img-container {
        background: #ececec;
      }
    }
  }

  .sc-img-container {
    display: inline-flex;
    border-radius: 4px;
    padding: 10px;
    height: 150px;
    width: 150px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .sc-company-name {
    margin-top: 10px;
    font-weight: bold;
    font-size: 1.2em;
  }

  .sc-list {
    gap: 10px;
    display: flex;
    flex-wrap: wrap;
  }

  .sc-inner {
    padding: 20px;
  }

  .sc-wrap {
    flex-grow: 1;
    box-shadow: 0 3px 5px 0 #0000003d;
    align-self: center;
    max-width: 900px;
    width: 100%;
    padding: 30px;
  }
}
