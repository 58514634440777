.personal-docs-section {
  display: flex;
  align-items: center;
  justify-content: center;

  .error-msg {
    bottom: -30px;
    text-align: center;
    width: 100%;

    &.inner-error {
      position: absolute;
    }
  }
}

.upload-file {

  &-upload-wrap {
    min-height: 141px;
  }

  &-wrapper {
    min-width: 231px;
  }

  &-wrap {
    cursor: pointer;

    .upload-label {
      font-size: 16px;
      width: 100%;
    }

    .icon-wrap {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
    }

    .icon-wrap span {
      background: #333;
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffcd0d;
      opacity: .8;
      border-radius: 50%;
      font-size: 30px;
    }

    .icon-wrap img {
      background: #333;
      width: 60px;
      height: 60px;
      opacity: .8;
      border-radius: 50%;
      padding: 11px;
    }
  }

  &-placeholder, &-upload-wrap {
    position: relative;
  }

  &-upload-wrap {

    .finished-check {
      display: flex;
    }

    .finished-upload {
      display: none !important;
    }

  }

  &-upload-wrap:hover {

    .finished-check {
      display: none;
    }

    .finished-upload {
      display: flex !important;
    }

  }

  &-image {
    width: 231px;
    height: 141px;
    object-fit: scale-down;
  }
}
