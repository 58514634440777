.places {
  .wrapper {
    display: flex;
    margin-bottom: 50px;

    .slider {
      width: 70%;
      padding: 0 10px;

      .rail {
        position: relative;
        width: 100%;
        height: 40px;
        padding: 40px 20px 0 20px;
        margin: -20px 0 0;
        border-radius: 2px;
        cursor: pointer;

        &::before {
          position: absolute;
          top: 20px;
          left: 0;
          content: ' ';
          width: 100%;
          height: 4px;
          background-color: rgba(0, 0, 0, 0.4);
        }
      }

      .track {
        position: relative;
        height: 4px;
        top: 4px;
        z-index: 1;
        margin-left: 0 !important;
        border-radius: 5;
        cursor: pointer;
      }

      .handle {
        position: relative;
        top: 16px;
        margin-left: 0;
        margin-right: 0;
        z-index: 2;
        width: 14px;
        height: 14px;
        text-align: center;
        cursor: pointer;
        border-radius: 14px;
        background-color: white;
        color: transparent;
        padding: 0;
      }
    }
    .field {
      display: inline-flex;
      align-items: center;
      padding-left: 5px;
      padding-bottom: 18px;

      input {
        width: 30px;
        height: 30px;
        text-align: center;
        border-radius: 4px 0 0 4px;
        border: 1px solid $primary;
      }

      .actions {
        margin-right: 5px;
        button {
          display: flex;
          height: 15px;
          width: 25px;
          margin: 0;
          align-items: center;
          justify-content: center;
          border: 1px solid $primary;
          background: white;
          color: $primary;
          border-radius: 4px;
          cursor: pointer;
          padding: 0;

          &:first-child {
            border-radius: 0 4px 0 0;
            border-left: 1px transparent;
            border-bottom: 1px transparent;

            span {
              transform: rotate(180deg);
            }
          }
          &:last-child {
            border-radius: 0 0 4px 0;
            border-left: 1px transparent;

            span {
              transform: rotate(0deg);
            }
          }

          span {
            background: url('/assets/images/grey-chevron.svg') no-repeat;
            background-size: contain;
            background-position: 0 2px;

            width: 10px;
            height: 10px;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .places {
    .wrapper {
      display: flex;
      margin-bottom: 50px;

      .slider {
        width: 80%;
        padding: 0;
      }

      .field {
        padding-left: 10px;

        input {font-size: 16px;}
      }
    }
  }
}
