/* -------------------------------------------------------
    COLORS
---------------------------------------------------------- */

$accent: #ffcd0d;
$accent2: #ffde00;
$input-border: #d3d0d0;
$primary: #cccccc;
$secondary: #333333;
$light: #e1e1e1;
$dark: #040507;
$background: #f4f4f4;
$background-light: #f9f9f9;
$background-dark: #eaebee;

$color-grey1: #cfcfcf;
$color-grey2: #c1c1c1;
$color-grey3: #bbbbbb;
$color-grey4: #aaaaaa;

$color-shadow1: rgba(0, 0, 0, 0.19);
$color-shadow2: rgba(0, 0, 0, 0.2);
$color-shadow3: rgba(0, 0, 0, 0.62);

$color-soft-white: #efefef;
$color-white: #ffffff;

$danger: #bd3632;

/* -------------------------------------------------------
    TEMP
---------------------------------------------------------- */

$tempGrey: #d9d9d9;

/* -------------------------------------------------------
    FONTS
---------------------------------------------------------- */

@mixin font-weight-light() {
  font-weight: 300;
}

/* -------------------------------------------------------
    DIMENSIONS
---------------------------------------------------------- */

$profilePageMobileFooterHeight: 70px;
$mobile_nav_width: 260px;
$newPagesWidth: 1150px;
$newHeaderHeight: 60px;
$footerMargin: 30px;
$container-width: 960px;

/* -------------------------------------------------------
    Z-INDEX
---------------------------------------------------------- */

$bootstrap_dropdown_z_index: 500;
$tel_input_z_index: 510;
$loading_booking_overlay_z_index: 900;
$header_z_index: 9000;
$nav_bar_z_index: 9001;
$cookie_alert_z_index: 9020;
$modal_backdrop_z_index: 9030;
$modal_main_z_index: 9040;
$error_modal_backdrop_z_index: 9050;
$error_modal_main_z_index: 9060;
$date_picker_modal_z_index: 9065;
$modal_outer_close_z_index: 9070;
$app_spinner_z_index: 9080;
$flash_message_z_index: 9090;
