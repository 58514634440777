html {
  position: relative;
  min-height: 100%;
}

html,
body {
  padding: 0;
  margin: 0;
}

body {
  font-family: 'MainFont', Palatino, serif;
  line-height: 1.4;
  font-size: 14px;
  color: $secondary;
}

h2 {
  font-size: 30px;
  margin: 35px 0;
}

hr {
  background: #e2e2e2;
  border: none;
  height: 1px;
  margin: 0;
}

.heading {
  &-lg {
    text-align: center;
    font-size: 3.4rem;
  }

  &-sm {
    font-size: 20px;
    font-weight: 700;
  }
}

.text {
  &-white {
    color: #ffffff !important;
  }

  &-accent {
    color: $accent !important;
  }

  &-primary {
    color: $primary !important;
  }

  &-secondary {
    color: $secondary !important;
  }

  &-light {
    color: $light !important;
  }

  &-dark {
    color: $dark !important;
  }

  &-bg {
    color: $background !important;
  }

  &-bg-light {
    color: $background-light !important;
  }

  &-bg-dark {
    color: $background-dark !important;
  }

  &-danger {
    color: $danger !important;
  }
}

.background {
  &-white {
    background-color: #ffffff !important;
  }

  &-accent {
    background-color: $accent !important;
  }

  &-primary {
    background-color: $primary !important;
  }

  &-secondary {
    background-color: $secondary !important;
  }

  &-light {
    background-color: $light !important;
  }

  &-dark {
    background-color: $dark !important;
  }

  &-bg {
    background-color: $background !important;
  }

  &-bg-light {
    background-color: $background-light !important;
  }

  &-bg-dark {
    background-color: $background-dark !important;
  }

  &-danger {
    background-color: $danger !important;
  }
}

.highlight {
  font-weight: bold;
  color: #4e4e4e;
}

.highlight2 {
  font-weight: bold;
  color: #3e3e3e;
}

.color-hightlight-1 {
  color: #545454;
}

.color-hightlight-2 {
  color: rgb(54, 54, 54);
}

.av-height {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.remove-row-margin {
  margin: 0;
}

.remove-col-padding {
  padding: 0;
}

@media (max-width: 991px) {
  body {
    padding: 0;
  }
}

@media (max-width: 767px) {
  body {
    padding: 0;
  }

  main {
    padding-top: 60px;
  }

  .heading {
    &-lg {
      font-size: 1.5rem;
    }
  }
}

input::placeholder {
  font-family: inherit;
  color: #848484;
}

input {
  font-family: inherit;
}

button {
  font-family: inherit;
}
