.site-container {
  &._my-bookings,
  &._edit-booking {
    .booking-history {
      min-height: 78vh;
    }

    .footer {
      margin-top: 0;

      hr {
        max-width: none;
      }
    }
  }
}

.booking-history {
  min-height: calc(100vh - #{$newHeaderHeight});
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.24);
  padding: 30px 30px 40px 30px;
  margin: 0 auto;
  color: #333;

  &-plate-number {
    font-weight: 500;
  }

  &-title {
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .tab-pane {
    display: none;

    &.active {
      display: block;
    }
  }

  .nav-tabs {
    li {
      font-size: 14px;
      width: 50%;
      display: inline-block;
      border-width: 1px;
      border-color: $tempGrey;

      &:first-child {
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
        border-style: solid none solid solid;
      }

      &:last-child {
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
        border-style: solid solid solid none;
      }

      button {
        outline: none;
        text-decoration: none;
        padding: 5px;
        display: inline-flex;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: #595959;
        font-weight: bold;
        border: 0;
        background: transparent;
        cursor: pointer;

        &.active {
          color: #333;
          font-weight: 500;
        }
      }
    }
  }
}

@media (min-width: 1000px) {
  .booking-history {
    max-width: $newPagesWidth;
    padding-right: 90px;
    padding-left: 90px;
  }
}

@media (max-width: 767px) {
  .site-container._my-bookings {
    .booking-history {
      min-height: 45vh;
    }
  }

  .booking-history {
    padding-right: 15px;
    padding-left: 15px;
  }
}
