.table-striped {
  >thead {
    background-color: #e1e2e3;
  }

  >tbody {
    >tr {
      &:nth-of-type(odd) {
        background-color: #fdfefe;
      }
      &:nth-of-type(even) {
        background-color: #e1e2e3;
      }
    }
  }

  tr {
    td {
      padding: 20px;
    }
  }

  tr {
    th {
      padding: 15px;
      vertical-align: middle;
    }
  }
}