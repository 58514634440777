.btn {
  text-transform: lowercase;
  text-shadow: none;
  border: none;
  padding: 0 20px;
  line-height: 45px;
  height: 45px;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none !important;
  outline: none;
  font-size: 14px;
  border-radius: 4px;
  background-color: #ffcc33;
  font-family: inherit;

  &:hover {
    color: inherit;
  }

  &:disabled {
    background: $light;
    cursor: not-allowed;
    color: #8e8e8e;

    &:hover {
      background: $light;
    }
  }

  &-no-angle {
    &:after {
      content: none;
    }
  }

  &-link {
    text-decoration: underline;
    cursor: pointer;
  }

  &-accent {
    background-color: $accent;
    color: #000000;
  }

  &-primary {
    background-color: $primary;
    color: #000000;

    &:hover {
      background-color: #dddddd;
    }
  }

  &-secondary {
    background-color: $secondary;
    color: #ffffff;
  }

  &-sm {
    height: 32px;
    line-height: 32px;
    min-width: 185px;
    font-size: 9px;

    &:after {
      font-size: 12px;
      line-height: 32px;
    }
  }

  &-md-v {
    height: 40px;
    line-height: 40px;
    font-size: 12px;

    &:after {
      font-size: 15px;
      line-height: 39px;
    }
  }

  &-md {
    min-width: 230px;
  }

  &-w-210 {
    min-width: 210px;
  }

  &-lg {
    min-width: 245px;
    padding: 0 10px;

    &:after {
      margin-right: 5px;
    }
  }

  &.w-100 {
    min-width: auto;
  }
}

.primary-btn,
.secondary-btn {
  transition: all ease 0.3s !important;
}

.primary-btn:hover:not(:disabled):not(.sub-class-disabled),
.secondary-btn:hover:not(:disabled):not(.sub-class-disabled) {
  color: black !important;
  background-color: #ebebeb !important;
}

.secondary-btn:hover:not(:disabled) {
  border-color: #ebebeb !important;
}

.primary-btn:disabled,
.secondary-btn:disabled {
  @include disabledButtonColors;
}
