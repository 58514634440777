.booking-edit-list {
  .generic-submit-button {
    min-width: 200px;
  }
}

@media (max-width: 767px) {
  .booking-edit-list {
    .generic-submit-button {
      min-width: auto;
      width: 100%;
    }
  }
}
