.professional-page {


  .upload-file-wrapper {
    margin: 0;
  }

  @media (min-width: 992px) {
    .container {
      width: 980px;
    }
  }

  h1, h2, h3 {
    margin: 0;
    margin-bottom: 15px;
    font-size: 25px;
    font-weight: normal;

    strong {
      font-weight: normal;
    }
  }

  .section1:first-child {
    margin: 35px 0 35px 0;
  }

  .section1 {
    text-align: center;
    margin: 15px 0 25px 0;

    .paragraph1 {
      font-size: 16px;
    }
  }

  .no-title {
    margin: 25px 0 25px 0;
  }

  .section2 {
    padding: 30px 0;
    font-size: 16px;
    text-align: center;
    background: $background-dark;
  }

  .formular {
    margin: 35px 0 55px 0;

    .description {
      margin: 30px 0 45px;
      text-align: center;
      font-size: 20px;
    }

    .upload-label {
      font-size: 16px;
      color: #666666;
      margin-top: 10px;
    }
  }

  .contract {
    padding: 40px 0 70px;

    .description {
      margin: 30px 0 60px;
      text-align: center;
      font-size: 20px;

      .larger {
        font-size: 24px;
        font-weight: 700;
      }
    }

    .message-wrapper {
      padding: 0 40px;
    }

    .mandatory-fields-text {
      margin-bottom: 75px;
    }
  }
}

@media (max-width: 767px) {
  .professional-page {
    .paragraph2, h1 {
      font-size: 18px;
    }

    .section1 {
      font-size: 16px;
    }

    .section5 {
      .message-wrapper {
        padding: 0;
      }

      .mandatory-fields-text {
        margin-bottom: 20px;
      }
    }
  }
}
