.pickerModal {
  &__select {
    &--month,
    &--year {
      height: auto;
    }
  }

  z-index: $date_picker_modal_z_index;
}
