.welcome-message-page {
  min-height: calc(100vh - #{$newHeaderHeight} - #{$footerMargin});

  .scope {
    &-container {
      margin: 15vh auto;
      max-width: 500px;
      padding: 0 20px;
      text-align: center;
    }

    &-page-body {
      margin-top: 40px;
    }

    &-img-container {
      max-height: 125px;
      display: flex;
      justify-content: center;
    }

    &-title-msg {
      margin-top: 30px;
      font-weight: bold;
      font-size: 1.2em;
    }

    &-info-msg {
      font-size: 1.1em;
      margin-top: 15px;
    }

    &-page-footer {
      margin-top: 30px;
    }

    &-submit-button {
      cursor: pointer;
      height: 45px;
      min-width: 300px;
      border: 0;
      border-radius: 4px;
      outline: none;
      font-size: 15px;
      font-weight: bold;
    }
  }
}

@media (max-width: 767px) {
  .site-container._welcome-message {
    .footer {
      display: none;
    }
  }

  .welcome-message-page {
    min-height: calc(100vh - #{$newHeaderHeight});
    display: flex;
    align-items: center;

    .scope {
      &-container {
        margin-top: 10px;
        margin-bottom: $profilePageMobileFooterHeight;
      }

      &-page-body {
        margin-top: 5vh;
        margin-bottom: 15vh;
      }

      &-page-footer {
        background: white;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.24);
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: $profilePageMobileFooterHeight;
      }

      &-submit-button {
        min-width: 100%;
        font-size: 13px;
      }
    }
  }
}
